<template>
    <div class="flex justify-content-end">
        <Chip
            v-if="metricValue.attachments"
            class="badge--noclick custom-metric-value__attachments"
        >
            <i class="fa-light fa-file pr-1"></i>

            {{ displayAttachmentText(metricValue.attachments.length) }}
        </Chip>
    </div>
</template>

<script>
import Chip from "primevue/chip";

export default {
    components: {
        Chip,
    },
    props: {
        metricValue: {
            type: Object,
            required: true,
        },
    },
    methods: {
        displayAttachmentText(count) {
            return count === 1
                ? `${count} ${this.$t("file.one")}`
                : `${count} ${this.$t("file.other")}`;
        },
    },
};
</script>
