<template>
    <div>
        <div d3-chart :id="chartKey" v-show="!hideGraph"></div>
        <div v-if="hideGraph" class="ml-2 text-gray-500 text-tiny">
            {{ $t("charts_no_data") }}
        </div>
    </div>
</template>

<script>
import * as d3 from "d3";

export default {
    props: {
        data: {
            type: Array,
            required: true,
        },
        labelKey: {
            type: String,
            required: true,
        },
        idKey: {
            type: String,
            required: true,
        },
        chartKey: {
            type: String,
            required: false,
            default: "d3-chart",
        },
        barStyle: {
            type: String,
            required: false,
            default: "bar__blue",
        },
        stackKeys: {
            type: Array,
            required: true,
        },
        highlighted: {
            type: [Number, String],
            required: false,
            default: null,
        },
        width: {
            type: Number,
            required: false,
            default: 1000,
        },
        height: {
            type: Number,
            required: false,
            default: 300,
        },
        padding: {
            type: Number,
            required: false,
            default: 0.95,
        },
        targetLine: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            svg: null,
            xScale: null,
            yScale: null,
            graph: null,
            groups: [],
            subgroups: [],
        };
    },
    computed: {
        highestDataPoint() {
            const emissions = this.data
                .map((x) => x.emissions)
                .map((y) => Object.values(y));
            const sums = emissions.map((x) =>
                x.reduce((partialSum, a) => partialSum + a, 0)
            );
            const highest = Math.max(...sums);
            return highest + (highest / 100) * 12;
        },
        hideGraph() {
            let emissions = 0;
            this.data.forEach((item) => {
                emissions += item.emissions;
            });

            return emissions === 0;
        },
        stackedData() {
            return this.data.filter((x) => typeof x.emissions === "object");
        },
        barData() {
            return this.data.filter((x) => typeof x.emissions !== "object");
        },
    },
    mounted() {
        this.generateChart();
    },
    methods: {
        generateChart() {
            this.svg = d3
                .select("#" + this.chartKey)
                .append("svg")
                .attr("viewBox", `0 0 ${this.width} ${this.height}`);

            // Create the Y scale
            this.yScale = d3
                .scaleLinear()
                .rangeRound([300, 0])
                .domain([0, this.highestDataPoint]);

            // Create the X scale
            this.xScale = d3
                .scaleBand()
                .range([0, this.width - 50])
                .padding(this.padding)
                .domain(this.data.map((x) => x[this.idKey]));

            // Draw the scales on the SVG
            this.svg
                .append("g")
                .attr("transform", `translate(60, 285)`)
                .attr("class", "text-capitalize")
                .attr("style", "font-size: .5rem")
                .call(
                    d3
                        .axisBottom(this.xScale)
                        .tickSize(0)
                        .tickFormat((i, d) => this.data[d][this.labelKey])
                );

            this.svg
                .append("g")
                .attr("transform", "translate(60, -20)")
                .call(
                    d3
                        .axisLeft(this.yScale)
                        .tickSize(-this.width + 70)
                        .tickFormat(d3.format("~s"))
                );

            // Add the colour range to the stacked bars
            const color = d3
                .scaleOrdinal()
                .domain(this.stackKeys)
                .range(["#5bc8ae", "#a6b4c6", "#d1d0ea"]);

            const stackedData = d3.stack().keys(this.stackKeys)(
                this.stackedData.map((x) => {
                    if (typeof x.emissions !== "object") return {};
                    return {
                        ...x.emissions,
                        ...x,
                    };
                })
            );

            // Add the stacked bars
            this.graph = this.svg
                .append("g")
                .selectAll("g")
                // Enter in the stack data = loop key per key = group per group
                .data(stackedData)
                .join("g")
                .attr("fill", (d) => color(d.key))
                .selectAll("rect")
                // enter a second time = loop subgroup per subgroup to add all rectangles
                .data((d) => d)
                .join("rect")
                .attr("class", `bar bar__vertical-stacked`)
                .attr("x", (d) => this.xScale(d.data[this.idKey]))
                .attr("y", (d) => this.yScale(d[1]))
                .attr("height", (d) => this.yScale(d[0]) - this.yScale(d[1]))
                .attr("width", this.xScale.bandwidth())
                .attr("rx", 1.5)
                .on("mouseover", (e, d) => this.addScopedHoverData(e, d))
                .on("mouseout", (e) => this.addScopedHoverData(e, null))
                .on("mousemove", (e, d) => this.addScopedHoverData(e, d))
                .on("mouseup", (e, d) =>
                    this.$emit("bar-clicked", {
                        ...d,
                        click: "evolution",
                        scope: this.getScope(d),
                    })
                );

            this.svg.selectAll("g .domain").attr("stroke", "transparent");

            this.svg.selectAll("g .tick").attr("stroke-dasharray", "3,3");

            this.svg.selectAll("g .tick line").attr("stroke", "#adb5bd");

            this.svg
                .selectAll("g .predictions")
                .data(this.barData)
                .enter()
                .append("rect")
                .attr("class", () => {
                    let classes = `bar ${this.barStyle} bar__vertical-stacked bar__faded`;
                    return classes;
                })
                .classed(`bar ${this.barStyle} bar__vertical`, true)
                .attr("width", this.xScale.bandwidth())
                .attr("height", (data) => 300 - this.yScale(data.emissions))
                .attr("rx", 1.5)
                .attr("x", (data) => this.xScale(data[this.idKey]))
                .attr("y", (data) => this.yScale(data.emissions))
                .on("mouseover", (e, data) => this.addHoverData(e, data))
                .on("mouseout", (e) => this.addHoverData(e, null))
                .on("mousemove", (e, data) => this.addHoverData(e, data))
                .on("mouseup", (e, data) => this.$emit("bar-clicked", data));

            this.svg
                .append("path")
                .datum(this.targetLine.data)
                .attr("class", `trend-line`)
                .attr(
                    "d",
                    d3
                        .line()
                        .x((d) => this.xScale(d[this.idKey]))
                        .y((d) => this.yScale(d.emissions))
                );
        },
        addScopedHoverData(e, data) {
            const coords = d3.pointer(e);
            this.svg.selectAll(".hover-data, .hover-data__background").remove();

            if (data !== null) {
                const scope = this.getScope(data);
                const hoverbox = this.svg
                    .append("rect")
                    .attr(
                        "transform",
                        `translate(${coords[0]}, ${
                            coords[1] > this.height / 2
                                ? coords[1] - 55
                                : coords[1] - 10
                        })`
                    )
                    .attr("fill", "white")
                    .attr("height", 30)
                    .attr("stroke", "black")
                    .attr("rx", 5)
                    .classed("hover-data__background", true);
                const hovertext = this.svg
                    .append("text")
                    .attr(
                        "transform",
                        `translate(${coords[0] + 10}, ${
                            coords[1] > this.height / 2
                                ? coords[1] - 35
                                : coords[1] + 10
                        })`
                    )
                    .classed("hover-data", true)
                    .text(
                        `Scope ${scope}: ${this.$checkDecimals(
                            data[1] - data[0]
                        )} ${data.data.unit}`
                    );
                const hovertextWidth = hovertext._groups[0][0].getBBox().width;
                hoverbox.attr("width", hovertextWidth + 20);
            }
        },
        addHoverData(e, data) {
            const coords = d3.pointer(e);
            this.svg.selectAll(".hover-data, .hover-data__background").remove();

            if (data !== null) {
                const hoverbox = this.svg
                    .append("rect")
                    .attr(
                        "transform",
                        `translate(${coords[0]}, ${coords[1] - 55})`
                    )
                    .attr("fill", "white")
                    .attr("height", 30)
                    .attr("stroke", "black")
                    .attr("rx", 5)
                    .classed("hover-data__background", true);
                const hovertext = this.svg
                    .append("text")
                    .attr(
                        "transform",
                        `translate(${coords[0] + 10}, ${coords[1] - 35})`
                    )
                    .classed("hover-data", true)
                    .text(
                        `${this.$checkDecimals(data.emissions)} ${data.unit}`
                    );
                const hovertextWidth = hovertext._groups[0][0].getBBox().width;
                hoverbox.attr("width", hovertextWidth + 20);
            }
        },
        getScope(data) {
            // This is a weird way to get the scope, but for now it seems the best.
            // We calculate the original value and round it because we sometimes get different
            // rounding. This should work in 99.9% of cases.
            const values = Object.values(data.data.emissions);
            const index = values.findIndex(
                (x) => x.toFixed(2) === (data[1] - data[0]).toFixed(2)
            );
            return index + 1;
        },
    },
};
</script>
