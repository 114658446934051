import { app } from "@/main";

import CcInput from "@/components/elements/form_elements/CcInput";
import CcInputTel from "@/components/elements/form_elements/CcInputTel";
import CcSelect from "@/components/elements/form_elements/CcSelect";
import CcSelectIcon from "@/components/elements/form_elements/CcSelectIcon.vue";
import CcDropdown from "@/components/elements/form_elements/CcDropdown";

const addGlobalComponents = (app) => {
    app.component("CcSelect", CcSelect);
    app.component("CcInput", CcInput);
    app.component("CcInputTel", CcInputTel);
    app.component("CcSelectIcon", CcSelectIcon);
    app.component("CcDropdown", CcDropdown);
};

export default addGlobalComponents;
