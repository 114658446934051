<template>
    <div class="flex flex-column gap-4">
        <Card>
            <template #title>
                <h2>{{ $t("Team") }}</h2>
            </template>

            <template #content>
                <table class="cc-table">
                    <thead>
                        <tr>
                            <td>{{ $t("label_name") }}</td>
                            <td>{{ $t("role") }}</td>
                            <td>{{ $t("label_site_access") }}</td>
                            <td>{{ $t("label_business_unit_access") }}</td>
                            <td>{{ $t("label_tag_access") }}</td>
                            <td></td>
                        </tr>
                    </thead>
                    <tr v-for="member in team" :key="member.id">
                        <td>
                            {{ `${member.first_name} ${member.last_name}` }}
                        </td>
                        <td>
                            {{ $t(`form_${member.role.toLowerCase()}`) }}
                        </td>
                        <td>
                            <SiteAccessField :member="member" />
                        </td>
                        <td>
                            <BusinessUnitAccessField :member="member" />
                        </td>
                        <td>
                            <TagsAccessField :member="member" />
                        </td>
                        <td>
                            <TeamMemberActionsField
                                :member="member"
                                @edit="handleEditUser(member)"
                                @remove="handleRemove(member)"
                            />
                        </td>
                    </tr>
                </table>
            </template>
        </Card>

        <Invites />

        <team-edit-modal
            :showModal="showUserForm"
            :editUser="editUser"
            @update:showModal="showUserForm = $event"
            @saved="handleSaved"
        ></team-edit-modal>
    </div>
</template>

<script>
import Invites from "./team/Invites.vue";
import TeamEditModal from "./team/TeamEditModal.vue";
import Card from "primevue/card";
import SiteAccessField from "./team/table-fields/SiteAccessField.vue";
import BusinessUnitAccessField from "./team/table-fields/BusinessUnitAccessField.vue";
import TagsAccessField from "./team/table-fields/TagsAccessField.vue";
import TeamMemberActionsField from "./team/table-fields/TeamMemberActionsField.vue";

import companyApi from "@/api/company";
import swal from "sweetalert2";

export default {
    name: "CCTeam",
    components: {
        TeamEditModal,
        Invites,
        Card,
        SiteAccessField,
        BusinessUnitAccessField,
        TagsAccessField,
        TeamMemberActionsField,
    },
    data() {
        return {
            team: [],
            showUserForm: false,
            editUser: {},
        };
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            this.getTeam();
        },
        async getTeam() {
            try {
                const response = await companyApi.getTeam();
                this.team = response.data.filter(
                    (user) => !this.excludeRole(user.role)
                );
            } catch (error) {
                console.log(error);
            }
        },
        async deleteUser(user) {
            try {
                await companyApi.deleteUser(user);
                this.init();
            } catch (error) {
                console.log(error);
            }
        },
        handleEditUser(user) {
            this.editUser = user;
            this.showUserForm = true;
        },
        handleSaved() {
            this.init();
            this.showUserForm = false;
        },
        async handleRemove(item) {
            const result = await swal.fire({
                title: this.$t("team_handleRemove_title"),
                text: this.$t("team_handleRemove_text", {
                    first_name: item.first_name,
                    last_name: item.last_name,
                }),
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: this.$t("cancel"),
            });

            if (result.value) this.deleteUser(item);
        },
        excludeRole(role) {
            const rolesToHide = ["support"];
            return rolesToHide.includes(role);
        },
    },
};
</script>
