<template>
    <!-- Empty Custom Metrics Page-->
    <div class="flex flex-column justify-content-center align-items-center">
        <div class="custom-metric__container">
            <img
                :src="bgImagePath"
                alt="Cloud image"
                class="custom-metric__img"
            />
            <div class="custom-metric__text-overlay">
                <h2>{{ $t("empty_metric_header") }}</h2>
                <p>
                    {{ $t("empty_metric_text") }}
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import no_metrics_image from "@/assets/images/metrics/metrics_background.png";
export default {
    name: "EmptyMetricView",
    data() {
        return {
            bgImagePath: no_metrics_image,
        };
    },
};
</script>
