<template>
    <div>
        <div v-if="!member.access_all_tags && tagsAccessArray.length > 0">
            <Badge class="badge--blue badge--noclick">
                {{ tagsAccessArray[0].title }}
            </Badge>
            <p
                v-if="tagsAccessArray.length > 1"
                class="text-gray-500 light text-small ml-1 mb-0"
            >
                {{ $t("and") }}
                {{ tagsAccessArray.length - 1 }}
                {{ $t("more") }}
            </p>
        </div>

        <Badge class="badge--blue badge--noclick" v-else
            >{{ $t("all_tags") }}
        </Badge>
    </div>
</template>

<script setup lang="ts">
import { type PropType, computed } from "vue";
import { useStore } from "vuex";
const store = useStore();
import Badge from "primevue/badge";

const props = defineProps({
    member: {
        type: Object as PropType<TTeamMember>,
        required: true,
    },
});

const tagsAccessArray = computed(() => {
    const tags = props.member.constraints?.tag_ids;

    if (!tags) return [];
    const res = tags.map((x) => {
        return store.getters.getTag(x);
    });

    return res;
});
</script>
