<template>
    <Dialog
        :header="title"
        v-model:visible="localShowModal"
        modal
        :style="{ width: '50%' }"
    >
        <form @submit.prevent="save" class="cc-form" id="site-group-form">
            <div class="cc-form-group">
                <label for="name">{{ $t("label_name") }}</label>
                <CcInput id="name" v-model="name" required></CcInput>
            </div>
        </form>
        <template #footer>
            <div class="flex justify-content-end gap-2">
                <Button
                    v-if="!isNew"
                    @click="deleteSiteGroup"
                    severity="danger"
                    :label="$t('label_delete_group')"
                ></Button>
                <Button
                    @click="$emit('update:modelValue', false)"
                    variant="secondary"
                    outlined
                    :label="$t('cancel')"
                ></Button>
                <Button form="site-group-form" type="submit"
                    >{{ $t("save") }}
                </Button>
            </div>
        </template>
    </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import siteGroupApi from "@/api/site_group.js";

export default {
    components: {
        Dialog,
        Button,
    },
    props: {
        siteGroup: {
            type: Object,
            required: false,
            default: () => {},
        },
        modelValue: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            name: "",
        };
    },
    computed: {
        isNew() {
            return !this.siteGroup || this.siteGroup.id <= 0;
        },
        title() {
            return this.isNew ? this.$t("new_group") : this.$t("edit_group");
        },
        localShowModal: {
            get() {
                return this.modelValue;
            },
            set(val) {
                if (val === false) this.reset();
                this.$emit("update:modelValue", val);
            },
        },
    },
    methods: {
        save() {
            let params = {
                name: this.name,
            };
            if (this.isNew) {
                siteGroupApi.addSiteGroup(params).then(() => {
                    this.reset();
                    this.$emit("update");
                    this.localShowModal = false;
                });
            } else {
                let id = this.siteGroup.id;
                siteGroupApi.updateSiteGroup(id, params).then(() => {
                    this.reset();
                    this.$emit("update");
                    this.localShowModal = false;
                });
            }
        },

        reset() {
            this.name = "";
        },

        setData() {
            if (this.isNew) {
                this.reset();
            } else {
                this.name = this.siteGroup.name;
            }
        },

        deleteSiteGroup() {
            this.$confirm.require({
                message: `Are you sure you want to delete ${this.siteGroup.name}?`,
                header: "Delete sitegroup?",
                icon: "fa fa-exclamation-triangle",
                rejectProps: {
                    label: this.$t("cancel"),
                    class: "secondary",
                    outlined: true,
                },
                acceptProps: {
                    label: this.$t("delete"),
                },
                accept: () => {
                    siteGroupApi.deleteSiteGroup(this.siteGroup.id).then(() => {
                        this.reset();
                        this.$emit("update");
                        this.localShowModal = false;
                    });
                },
            });
        },
    },
    watch: {
        siteGroup() {
            this.setData();
        },
    },
};
</script>
