<template>
    <div>
        <div
            class="menu-select__item"
            :class="
                state.id === selectedValidation
                    ? 'menu-select__item--selected'
                    : ''
            "
            v-for="state in states"
            :key="state.validation_state"
            @click="handleInput(state.validation_state)"
        >
            {{ state.name }}
        </div>
    </div>
</template>

<script>
import * as types from "@/store/types";

import { mapState } from "vuex";

export default {
    data() {
        return {
            states: [
                { validation_state: 1, name: this.$t("validated") },
                {
                    validation_state: 0,
                    name: this.$t("not_validated"),
                },
            ],
        };
    },
    computed: {
        ...mapState({
            selectedValidation: (state) => state.filters.validation,
        }),
    },
    methods: {
        handleInput(state) {
            this.$store.dispatch(types.SET_FILTER, { validation: state });
            this.$emit("selected");
        },
    },
};
</script>
