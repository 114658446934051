import http from "../http-common";

const api = {
    getActivity: (emissionDataId) => {
        return http.get(`/emission-activity/${emissionDataId}`);
    },

    getAllActivity: () => {
        return http.get(`/emission-activity/`);
    },
};

export default api;
