import store from "@/store";
export const createQueryString = (queryObject, addFilters) => {
    const query = [];
    const filters = addFilters // Merge two objects to prevent duplicates
        ? {
              ...store.getters.getFilters(),
              ...queryObject,
          }
        : queryObject;

    // Loop through the object and push the key and value to the query array
    for (const key in filters) {
        //if value is an array, push each value to the query array
        if (Array.isArray(filters[key])) {
            for (const value of filters[key]) {
                query.push({
                    key: `${encodeURIComponent(key)}[]`,
                    value: encodeURIComponent(value),
                });
            }
        } else {
            // Encode all the other stuff
            query.push({
                key: encodeURIComponent(key),
                value: encodeURIComponent(filters[key]),
            });
        }
    }

    // Join the query array with the & character
    const res = query
        .map(
            (q) =>
                `${q.key}=${
                    Array.isArray(q.value)
                        ? encodeURIComponent(JSON.stringify(q.value))
                        : q.value
                }`
        )
        .join("&");

    return res;
};
