<template>
    <Dialog
        id="error-modal"
        v-model:visible="showModal"
        modal
        :style="{ width: '30rem' }"
        @update:visible="redirect"
    >
        <template #header>
            <h3>{{ title }}</h3>
        </template>
        <p v-if="message === ''">
            {{ $t("pages_error_text") }}
        </p>
        <p v-else v-html="message"></p>
        <template #footer>
            <Button
                v-if="!hideOk"
                label="OK"
                icon="fa fa-check"
                @click="redirect"
            />
        </template>
    </Dialog>
</template>

<script>
import { mapState } from "vuex";
import Dialog from "primevue/dialog";
import Button from "primevue/button";

export default {
    name: "CCError",
    components: {
        Dialog,
        Button,
    },
    data() {
        return {
            showModal: false,
            message: "",
            hideOk: false,
            redirectOnOk: "",
            title: "Oops!",
            logout: false,
        };
    },
    computed: {
        ...mapState({
            error: (state) => state.error,
        }),
    },
    methods: {
        redirect() {
            // Logout automatically redirects, so no need to redirect again
            if (this.logout) {
                this.$auth.logout();
            }

            if (this.redirectOnOk !== "") {
                this.$router.push(this.redirectOnOk);
            }

            this.showModal = false;
        },
    },
    watch: {
        error: {
            handler(newVal, oldVal) {
                if (newVal === oldVal) return;

                this.showModal = true;
                this.message = newVal.error || "";
                this.hideOk = newVal.hideOk || false;
                this.redirectOnOk = newVal.redirectOnOk || "";
                this.title = newVal.title || "Oops!";
                this.logout = newVal.logout || false;
                this.$amplitude.logEvent("ERROR", newVal);
            },
        },
    },
};
</script>
