import http from "../http-common";

const api = {
    list: () => {
        return http.get("/periods");
    },

    details: (periodId) => {
        return http.get(`/period/${periodId}/details`);
    },

    add: (period) => {
        return http.post("/period/add", period);
    },

    edit: (periodId, period) => {
        return http.post(`/period/${periodId}/edit`, period);
    },

    lock: (periodId, lock) => {
        return http.post(`/period/${periodId}/lock/${lock}`);
    },

    delete: (periodId) => {
        return http.post(`/period/${periodId}/delete`);
    },
};

export default api;
