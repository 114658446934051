<template>
    <div>
        <div
            class="menu-select__item"
            v-for="tag in tags"
            :key="tag.id"
            :class="
                selectedTags.includes(tag.id)
                    ? 'menu-select__item--selected'
                    : ''
            "
            @click="handleInput(tag.id)"
        >
            {{ tag.title }}
        </div>
    </div>
</template>

<script>
import * as types from "@/store/types";

import { mapState } from "vuex";

export default {
    computed: {
        ...mapState({
            tags: (state) => state.data.tags,
            selectedTags: (state) => state.filters.tags,
        }),
    },
    methods: {
        handleInput(id) {
            // Check if the tag has been selected already
            const tagIndex = this.selectedTags.findIndex((x) => x === id);

            // If it hasn't, add it to the list of selected tags
            if (tagIndex === -1) {
                const selectedTags = [...this.selectedTags, id];
                return this.$store.dispatch(types.SET_FILTER, {
                    tags: selectedTags,
                });
            }

            // If it has, remove it from the list of selected tags
            const selectedTags = [
                ...this.selectedTags.slice(0, tagIndex),
                ...this.selectedTags.slice(tagIndex + 1),
            ];
            this.$store.dispatch(types.SET_FILTER, {
                tags: selectedTags,
            });
        },
    },
};
</script>
