import store from "@/store";

export const checkRights = (action, item) => {
    const user = store.state.user;
    if (!user) return false;
    return user.permissions.includes(`${action}:${item}`);
};

// Create the Vue plugin install feature
const can = {
    install: (app) => {
        app.config.globalProperties.$can = (action, item) =>
            checkRights(action, item);
    },
};

export default can;
