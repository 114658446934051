import amplitude from "amplitude-js";

export default {
    install: (app) => {
        amplitude.getInstance().init("07de7454ab2fcf4da05743a650d1880e");

        // eslint-disable-next-line
        app.config.globalProperties.$amplitude = amplitude.getInstance();
    },
};
