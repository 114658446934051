<template>
    <div>
        <Drawer
            v-model:visible="sidebarVisible"
            width="600px"
            position="right"
            class="w-full md:w-80 lg:w-5"
        >
            <template #header>
                <MetricSidebarHeader
                    :title="title"
                    @open-modal="toggleModal"
                    @handle-cancel="toggleModal"
                />
            </template>

            <!-- Sidebar content -->
            <div class="p-4">
                <metric-data-input
                    v-if="!selectedCustomMetricValue"
                    :custom-metric="metric"
                    @update:metric="handleCustomMetricInput"
                    @close="handleCancel"
                    @refresh="handleRefresh"
                    :modal="false"
                />
                <MetricValueForm
                    v-else
                    :customMetric="metric"
                    :metricValue="selectedCustomMetricValue"
                    @refresh="handleRefresh"
                />
            </div>
            <!-- Sidebar footer -->
            <template #footer>
                <!-- Metric values get autosaved, so this is only for a new metric -->
                <MetricSidebarButtons
                    v-if="metric && !selectedCustomMetricValue"
                    :selectedCustomMetric="selectedCustomMetric"
                    @cancel="handleCancel"
                />

                <MetricSidebarNavigation
                    v-if="selectedCustomMetricValue"
                    :selectedCustomMetricValue="selectedCustomMetricValue"
                    @navigate="handleNavigate"
                    @refresh="handleRefresh"
                    class="mb-1 px-4"
                />
            </template>
        </Drawer>

        <!-- MODAL -->
        <Dialog id="modal" v-model:visible="showModal" modal>
            <template #header>
                <div class="custom-metric-sidebar__header px-2">
                    <h2 class="mb-0">{{ title }}</h2>
                    <div>
                        <Button
                            id="minimize_button"
                            text
                            icon="fas fa-down-left-and-up-right-to-center"
                            @click="toggleModal"
                            v-tooltip.bottom="$t('minimize')"
                        >
                        </Button>
                    </div>
                </div>
            </template>
            <!-- Modal content -->
            <metric-data-input
                v-if="!selectedCustomMetricValue"
                :custom-metric="metric"
                @update:metric="handleCustomMetricInput"
                @close="handleCancel"
                :modal="true"
            />
            <MetricValueForm
                v-else
                :customMetric="metric"
                :metricValue="selectedCustomMetricValue"
                @refresh="handleRefresh"
            />

            <!-- Metric values get autosaved, so this is only for a new metric -->
            <MetricSidebarButtons
                v-if="metric && !selectedCustomMetricValue"
                :selectedCustomMetric="selectedCustomMetric"
                @cancel="handleCancel"
            />

            <!-- Modal footer -->
            <template #modal-footer>
                <div class="py-3 w-full">
                    <MetricSidebarNavigation
                        v-if="selectedCustomMetricValue"
                        :selectedCustomMetricValue="selectedCustomMetricValue"
                        @navigate="handleNavigate"
                        @refresh="handleRefresh"
                    />
                </div>
            </template>
        </Dialog>
    </div>
</template>

<script>
import ModalMixin from "@/mixins/custom_metrics/ModalMixin";
import MetricSidebarHeader from "./MetricSidebarHeader.vue";
import MetricSidebarNavigation from "./MetricSidebarNavigation.vue";

import MetricSidebarButtons from "./MetricSidebarButtons.vue";
import MetricDataInput from "./data-input/MetricDataInput.vue";
import MetricValueForm from "./data-input/MetricValueForm.vue";

import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Drawer from "primevue/drawer";

const newMetric = {
    name: "",
    unit: "",
    global_site: true,
    global_business_unit: true,
    description: "",
    siteIds: [],
    businessUnitIds: [],
};

export default {
    name: "MetricSidebar",
    mixins: [ModalMixin],
    components: {
        MetricSidebarNavigation,
        MetricSidebarHeader,
        MetricDataInput,
        MetricValueForm,
        MetricSidebarButtons,
        Drawer,
        Dialog,
        Button,
    },
    props: {
        selectedCustomMetric: {
            type: Object,
            required: false,
            default: null,
        },
        selectedCustomMetricValue: {
            type: Object,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            metric: null,
            loading: false,
            sidebarVisible: false,
            showModal: false,
        };
    },
    computed: {
        title() {
            return this.selectedCustomMetric?.id
                ? this.$t("edit_metric")
                : this.$t("create_new_metric");
        },
    },
    methods: {
        // SIDEBAR METHODS
        open() {
            this.sidebarVisible = true;
        },
        toggle() {
            this.sidebarVisible = !this.sidebarVisible;
        },
        close() {
            this.sidebarVisible = false;
            this.showModal = false;
            this.$emit("refresh");
        },
        toggleModal() {
            this.showModal = !this.showModal;
            this.sidebarVisible = !this.showModal;
        },
        handleCancel() {
            this.close();
            this.metric = structuredClone(newMetric);
            this.metricValue = null;
        },
        handleCustomMetricInput(value) {
            this.metric = value;
        },
        handleNavigate(value) {
            this.$emit("navigate-metric-values", value);
        },
        handleRefresh() {
            this.$emit("refresh");
        },
    },
    watch: {
        selectedCustomMetric: {
            handler(value) {
                if (value) {
                    this.metric = structuredClone(value);
                } else {
                    this.metric = structuredClone(newMetric);
                }
            },
            immediate: true,
        },
    },
};
</script>
