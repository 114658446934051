import http from "../http-common";

const api = {
    getSiteGroup: (siteGroupId) => {
        return http.get(`/site-group/${siteGroupId}/details`);
    },

    addSiteGroup: (params) => {
        return http.post("/site-group/add", params);
    },

    deleteSiteGroup: (siteGroupId) => {
        return http.post(`/site-group/${siteGroupId}/delete`);
    },

    updateSiteGroup: (siteGroupId, params) => {
        return http.post(`/site-group/${siteGroupId}/update`, params);
    },
};

export default api;
