<template>
    <div>
        <Card class="m-2">
            <!-- Accordion table header -->
            <template #content>
                <div
                    class="flex align-items-center justify-content-end pb-3 gap-3"
                >
                    <Select
                        v-if="customMetric.global_site === 0"
                        v-model="filteredSite"
                        :options="selectSites"
                        option-label="text"
                        option-value="value"
                        :placeholder="$t('select_site')"
                    >
                    </Select>
                    <Select
                        v-if="customMetric.global_business_unit === 0"
                        v-model="filteredBu"
                        :options="selectBu"
                        option-label="text"
                        option-value="value"
                        :placeholder="$t('select_bu')"
                    >
                    </Select>
                </div>
                <DataTable
                    :value="metricValues"
                    :loading="loading"
                    rowHover
                    @row-click="handleSelectedCustomMetricValue"
                >
                    <template #loading>
                        <Skeleton
                            v-for="(field, i) in fields"
                            :key="`load-${i}`"
                            width="100%"
                            height="3rem"
                            class="my-3"
                        >
                        </Skeleton>
                    </template>
                    <Column
                        field="site_id"
                        :header="$t('cm_site_label')"
                        sortable
                    >
                        <template #body="slotProps">
                            <MetricValueSite
                                :metricValue="slotProps.data"
                                :sites="sites"
                            />
                        </template>
                    </Column>
                    <Column
                        field="business_unit_id"
                        :header="$t('label_bu')"
                        sortable
                    >
                        <template #body="slotProps">
                            <MetricValueBU
                                :metricValue="slotProps.data"
                                :businessUnits="businessUnits"
                            />
                        </template>
                    </Column>
                    <Column
                        field="value"
                        :header="`${$t('label_value')} [${customMetric.unit}]`"
                        sortable
                    >
                        <template #body="slotProps">
                            <MetricValue :metricValue="slotProps.data" />
                        </template>
                    </Column>
                    <Column
                        field="description"
                        :header="$t('label_comments')"
                        sortable
                    >
                        <template #body="slotProps">
                            <MetricValueDescription
                                :metricValue="slotProps.data"
                                :sort="sort"
                            />
                        </template>
                    </Column>
                    <Column
                        field="attachments"
                        :header="$t('attachments')"
                        class="text-right pr-5"
                    >
                        <template #body="slotProps">
                            <MetricValueAttachment
                                :metricValue="slotProps.data"
                                class="pr-5"
                            />
                        </template>
                    </Column>
                </DataTable>
            </template>
        </Card>
    </div>
</template>

<script>
import customMetricsApi from "@/api/custom_metrics";
import sortSourcesUtil from "@/util/sort-sources.js";
import { mapState } from "vuex";
import MetricValue from "./tableColumns/MetricValue.vue";
import MetricValueAttachment from "./tableColumns/MetricValueAttachment.vue";
import MetricValueBU from "./tableColumns/MetricValueBU.vue";
import MetricValueDescription from "./tableColumns/MetricValueDescription.vue";
import MetricValueSite from "./tableColumns/MetricValueSite.vue";
import Card from "primevue/card";
import Select from "primevue/select";
import Skeleton from "primevue/skeleton";
import DataTable from "primevue/datatable";
import Column from "primevue/column";

export default {
    name: "MetricAccordionTable",
    components: {
        MetricValueSite,
        MetricValueBU,
        MetricValue,
        MetricValueDescription,
        MetricValueAttachment,
        Card,
        Select,
        Skeleton,
        DataTable,
        Column,
    },
    props: {
        customMetric: {
            type: Object,
            required: true,
            description: "The custom metric data.",
        },
        navigateMetricValue: {
            type: Number,
            required: false,
            default: 0,
            description: "Navigate to next or previous item",
        },
    },
    data() {
        return {
            filteredSite: null,
            filteredBu: null,
            metricValues: [],
            loading: true,
            iconClass: "",
            query: {
                sort: null,
                desc: null,
            },
            lastEmittedValue: null,
        };
    },
    mounted() {
        this.init();
    },
    computed: {
        ...mapState({
            periodId: (state) => state.filters.period,
            sites: (state) => state.data.sites,
            sort: (state) => state.dataInput.sort,
            businessUnits: (state) => state.data.businessUnits,
        }),
        selectSites() {
            const siteIds = this.customMetric.siteIds.split(",");
            const sites = this.sites
                .map((x) => {
                    return { value: x.id, text: x.name };
                })
                .filter((x) => siteIds.includes(x.value.toString()));
            sites.unshift({
                value: null,
                text: this.$t("all_sites"),
            });
            return sites;
        },

        selectBu() {
            const buIds = this.customMetric.businessUnitIds.split(",");
            const bu = this.businessUnits
                .map((x) => {
                    return { value: x.id, text: x.name };
                })
                .filter((x) => buIds.includes(x.value.toString()));
            bu.unshift({
                value: null,
                text: this.$t("all_business_units"),
            });
            return bu;
        },
        period() {
            return this.$store.getters.getCurrentPeriod();
        },
    },
    methods: {
        async init() {
            await this.getCustomMetricValues(this.customMetric.id);
        },

        async getCustomMetricValues(metricId, sortParam) {
            try {
                this.loading = true;
                const response = await customMetricsApi.getCustomMetricValues(
                    metricId,
                    this.periodId,
                    sortParam
                );

                const items = this.addLocalIds(response.data.data);
                let filteredItems = [...items];

                // Apply filters based on selected site and business unit
                if (this.filteredSite) {
                    filteredItems = filteredItems.filter(
                        (item) => item.site_id === this.site
                    );
                }
                if (this.filteredBu) {
                    filteredItems = filteredItems.filter(
                        (item) => item.business_unit_id === this.businessUnit
                    );
                }

                this.metricValues = filteredItems;

                this.sortMetricValues();
            } catch (error) {
                console.error(error);
            } finally {
                this.loading = false;
            }
        },

        // In here selected value gets identified and we are emitting event to parent component depending on value id
        handleSelectedCustomMetricValue(value, periodChanged = false) {
            if (!this.$can("update", "metric_value")) return;

            const metricValue = value?.data ?? value;
            if (!metricValue) return;

            this.$emit("select-metric-value", {
                metricValue,
                periodChanged,
                customMetric: this.customMetric,
            });

            this.lastEmittedValue = metricValue;
        },

        // Add local ID's, because some of the values have id = null
        addLocalIds(items) {
            return items.map((item) => {
                return {
                    ...item,
                    localId: `${item.metric_id}-${item.site_id}-${item.business_unit_id}`,
                };
            });
        },

        navigateValue(details) {
            const index = this.metricValues.findIndex((value) => {
                return value.localId === details.localId;
            });
            if (index === -1) return;

            this.handleSelectedCustomMetricValue(
                this.metricValues[index + details.direction]
            );
        },
        sortMetricValues() {
            if (this.sort) {
                this.metricValues = sortSourcesUtil(
                    this.metricValues,
                    this.sort,
                    this.sites,
                    this.businessUnits
                );
                return;
            }

            // Sort on localIds by default
            this.metricValues.sort((a, b) => {
                return a.localId.localeCompare(b.localId);
            });
        },
        sortTable(columnKey) {
            this.query.sort = columnKey;
            if (this.query.sort === columnKey) {
                this.query.desc = !this.query.desc;
            }
            this.query.desc
                ? (this.iconClass = "fa-duotone fa-arrow-up-arrow-down fa-xl")
                : (this.iconClass =
                      "fa-duotone fa-arrow-up-arrow-down fa-swap-opacity fa-xl");
        },
        async handlePeriodChanged() {
            await this.init();
            if (this.lastEmittedValue === null) return;

            const metric = this.metricValues.find(
                (x) => x.localId === this.lastEmittedValue.localId
            );

            this.handleSelectedCustomMetricValue(metric, true);
        },
    },
    watch: {
        periodId: {
            handler(newVal, oldVal) {
                if (newVal === oldVal) return;
                this.handlePeriodChanged();
            },
        },
        filteredSite: {
            handler(newVal, oldVal) {
                if (newVal === oldVal) return;
                this.init();
            },
        },
        filteredBu: {
            handler(newVal, oldVal) {
                if (newVal === oldVal) return;
                this.init();
            },
        },
        sort: {
            handler() {
                this.sortSources();
            },
        },
        navigateMetricValue: {
            handler(newVal) {
                if (newVal === 1 || newVal === -1) {
                    this.navigateValue(newVal === 1);
                }
                this.$nextTick(() => {
                    this.$emit("reset-navigation");
                });
            },
        },
        customMetric: {
            handler() {
                this.init();
            },
            deep: true,
        },
    },
};
</script>
