import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import Multiselect from "vue-multiselect";

// Import the Auth plugin here
import { createAuth0, useAuth0 } from "@auth0/auth0-vue";

// Global components
import addGlobalComponents from "./util/global";

// Import utils here
import decimals from "./util/decimals";
import formatTime from "./util/time";

// Import the Vuex store
import store from "./store/index.js";
// Add amplitude
import amplitude from "./util/amplitude";
// Add Vitally
import vitally from "./util/vitally";
// Add GA
// import VueGtag from "vue-gtag";
// Permissions checker
import can from "./util/permissions";
// Feature checker
import has_feature from "./util/features";
// Translations
import { i18n } from "./util/i18n";
// PrimeVue
import PrimeVue from "primevue/config";
import Aura from "@primevue/themes/aura";
import Tooltip from "primevue/tooltip";
import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";
// Sentry
import * as Sentry from "@sentry/vue";

export const app = createApp(App);

app.component("multiSelect", Multiselect);

// Import the Auth0 configuration
const domain = process.env.VUE_APP_auth0_domain;
const clientId = process.env.VUE_APP_auth0_client_id;
const audience = process.env.VUE_APP_auth0_audience;

document.title = "Futureproofed";

// Install the authentication plugin here
app.use(
    createAuth0({
        domain,
        clientId,
        authorizationParams: {
            redirect_uri: window.location.origin,
            audience,
        },
    })
);
app.config.globalProperties.$auth = useAuth0();

app.use(PrimeVue, {
    theme: {
        preset: Aura,
        options: {
            prefix: "p",
            darkModeSelector: ".dark-mode",
            cssLayer: true,
        },
    },
});
app.directive("tooltip", Tooltip);
app.use(ToastService);
app.use(ConfirmationService);
// Import our CSS AFTER the template CSS because we know better.
import "./assets/styles/main.scss";

app.use(amplitude);

app.use(vitally);

// Vue.use(
//     VueGtag,
//     {
//         config: { id: "G-YYEL8NK9DQ" },
//     },
//     router
// );

// Add custom utils to the Vue prototype
app.use(decimals);
app.use(formatTime);

app.use(can);

app.use(has_feature);
app.use(store);
app.use(router);
app.use(i18n);
addGlobalComponents(app);

// Only use sentry in production, so if node_env isn't production or there's "staging" in the url, we don't init

if (
    process.env.NODE_ENV === "production" &&
    !window.location.href.includes("staging")
) {
    // Configure Sentry
    Sentry.init({
        app,
        dsn: "https://ef5451197ed34d478a1bcc8471512691@o39657.ingest.us.sentry.io/5964049",
        integrations: [
            Sentry.browserTracingIntegration({ router }),
            Sentry.replayIntegration(),
        ],
        // Not using tracing, just error logging for now
        tracesSampleRate: 0,

        // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
        // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 1.0,
    });
}

app.mount("#app");
