<template>
    <Dialog
        :header="
            editPeriod === null ? $t('periodForm_add') : $t('periodForm_edit')
        "
        v-model:visible="localShowModal"
        modal
    >
        <form @submit.prevent="save" class="cc-form" id="period-form">
            <div class="cc-form-group">
                <label for="name">{{ $t("label_period") }}</label>
                <CcInput id="name" v-model="period.name" required></CcInput>
            </div>

            <div class="cc-form-group">
                <label>{{ $t("interval") }}</label>
                <CcSelect
                    v-model="interval"
                    :options="[
                        { value: 'yearly', text: $t('yearly') },
                        { value: 'quarterly', text: $t('quarterly') },
                        { value: 'custom', text: $t('custom_period') },
                    ]"
                    required
                ></CcSelect>
            </div>

            <div class="cc-form-group">
                <label>{{ $t("start_date") }}</label>
                <DatePicker
                    id="start-datepicker"
                    v-model="period.start_date"
                    required
                ></DatePicker>
            </div>

            <div class="cc-form-group">
                <label>{{ $t("end_date") }}</label>
                <DatePicker
                    id="end-datepicker"
                    v-model="period.end_date"
                    required
                    :disabled="interval !== 'custom'"
                ></DatePicker>
            </div>

            <div class="cc-form-group">
                <label>{{ $t("revenue") }}</label>
                <CcInput type="number" v-model="period.revenue" required />
            </div>

            <div class="cc-form-group cc-form-group--inline">
                <ToggleSwitch v-model="duplicateFromPreviousYear" />
                <label>{{
                    $t("periodForm_copyEmissionFactors_checkbox")
                }}</label>
            </div>

            <Transition name="slide-down" mode="out-in">
                <div
                    class="mt-4"
                    v-if="duplicateFromPreviousYear && !editPeriod"
                >
                    <div class="cc-form-group cc-form-group--inline">
                        <ToggleSwitch v-model="duplicate.values" />
                        <label>{{ $t("periodForm_copyValues") }}</label>
                    </div>

                    <div class="cc-form-group cc-form-group--inline">
                        <ToggleSwitch v-model="duplicate.descriptions" />
                        <label>{{ $t("periodForm_copyDescriptions") }}</label>
                    </div>
                </div>
            </Transition>
        </form>

        <template #footer>
            <div class="flex justify-content-end gap-3">
                <Button
                    :label="$t('cancel')"
                    @click="localShowModal = false"
                    outlined
                />
                <Button :label="$t('save')" type="submit" form="period-form" />
            </div>
        </template>
    </Dialog>
</template>

<script>
import periodApi from "@/api/period";
import Dialog from "primevue/dialog";
import DatePicker from "primevue/datepicker";
import ToggleSwitch from "primevue/toggleswitch";
import Button from "primevue/button";

const periodForm = {
    name: "",
    start_date: "",
    end_date: "",
    revenue: null,
};
export default {
    components: {
        Dialog,
        DatePicker,
        ToggleSwitch,
        Button,
    },
    props: {
        showModal: {
            type: Boolean,
            required: true,
        },
        previousPeriodId: {
            type: Number,
            required: false,
            default: -1,
        },
        startDate: {
            type: [Date, String],
            required: false,
            default: () => new Date().toISOString().split("T")[0],
        },
        editPeriod: {
            type: Object,
            required: false,
            default: () => null,
        },
    },
    data() {
        return {
            period: structuredClone(periodForm),
            duplicateFromPreviousYear: true,
            errorMessage: "",
            duplicate: {
                descriptions: true,
                values: false,
                notes: true,
            },
            interval: "yearly",
        };
    },
    computed: {
        localShowModal: {
            get() {
                return this.showModal;
            },
            set(val) {
                if (val === false) this.period = structuredClone(periodForm);
                this.$emit("update:showModal", val);
            },
        },
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            this.errorMessage = "";
            if (!this.editPeriod) {
                this.period.start_date = this.startDate;
                this.createFormattedEndDate();
            } else this.interval = "";
        },
        save() {
            if (this.period.id) this.update();
            else this.create();
        },
        async create() {
            if (this.duplicateFromPreviousYear) {
                this.period.duplicateFromPeriodId = this.previousPeriodId;
                this.period.duplicate = {
                    descriptions: this.duplicate.descriptions,
                    values: this.duplicate.values,
                    notes: this.duplicate.notes,
                };
            }

            try {
                await periodApi.add(this.period);
                this.$emit("close");
                this.$emit("saved");
            } catch (error) {
                console.log(error);
                this.errorMessage = error.response.data.message;
            }
        },
        async update() {
            this.period.start_date = this.formatDate(this.period.start_date);
            try {
                await periodApi.edit(this.period.id, { ...this.period });
                this.$emit("close");
                this.$emit("saved");
            } catch (error) {
                console.log(error);
                this.errorMessage = error.response.data.message;
            }
        },
        createFormattedEndDate() {
            const startDate = new Date(this.period.start_date);
            const addMonths = this.interval === "yearly" ? 12 : 3;
            if (!isNaN(startDate.getTime())) {
                let endDate = new Date(startDate);
                endDate.setMonth(startDate.getMonth() + addMonths);
                endDate.setDate(endDate.getDate() - 1);
                this.period.end_date = this.formatDate(endDate);
            }
        },
        formatDate(date) {
            return new Date(date).toISOString().split("T")[0];
        },
    },
    watch: {
        editPeriod: {
            handler(val) {
                if (val) {
                    this.period = val;
                } else {
                    this.period = structuredClone(periodForm);
                }
            },
            deep: true,
        },
        interval: {
            handler(val) {
                if (val === "custom") return;
                this.createFormattedEndDate();
            },
            immediate: true,
        },
        "period.start_date": {
            handler() {
                if (this.interval === "custom") return;
                this.createFormattedEndDate();
            },
        },
    },
};
</script>
