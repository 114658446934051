<template>
    <div class="flex gap-3 align-items-center">
        <language-selector />
        <Button
            icon="fa fa-user"
            @click="toggle"
            aria-haspopup="true"
            aria-controls="overlay_menu"
            rounded
        />
        <Menu ref="menu" id="overlay_menu" :model="items" :popup="true">
            <template #start>
                <span
                    class="inline-flex align-items-center gap-1 px-2 pt-2 m-1 mb-0"
                >
                    <Avatar
                        v-if="!auth0User?.picture"
                        :label="user.first_name[0]"
                        class="mr-2"
                        shape="circle"
                        @click="toggle"
                    />
                    <Avatar
                        v-else
                        :image="auth0User.picture"
                        shape="circle"
                        class="mr-2"
                    />
                    <span class="inline-flex flex-column">
                        <span class="font-bold mb-1">
                            {{ user.first_name }} {{ user.last_name }}
                        </span>
                        <span class="text-xs capitalize">{{ user.role }}</span>
                    </span>
                </span>
                <Divider class="m-2 mt-3" />
            </template>
            <template #item="{ item }">
                <div
                    class="flex align-items-center py-2 my-1 mx-2 cursor-pointer"
                >
                    <i :class="item.icon" class="mr-2"></i>
                    <span>{{ item.label }}</span>
                </div>
            </template>
        </Menu>

        <SwitchCompanyModal v-model="showSwitchCompany"></SwitchCompanyModal>
    </div>
</template>

<script>
import Button from "primevue/button";
import Menu from "primevue/menu";
import Avatar from "primevue/avatar";
import Divider from "primevue/divider";
import LanguageSelector from "@/components/elements/LanguageSelector.vue";
import SwitchCompanyModal from "@/components/navigation/switchCompany/SwitchCompanyModal.vue";
import { useAuth0 } from "@auth0/auth0-vue";
import { mapState } from "vuex";

export default {
    components: {
        SwitchCompanyModal,
        LanguageSelector,
        Button,
        Menu,
        Avatar,
        Divider,
    },
    data() {
        return {
            showSwitchCompany: false,
            items: [
                {
                    label: this.$t("my_profile"),
                    icon: "fa-solid fa-user",
                    command: () => this.goTo("/profile"),
                },
                {
                    label: this.$t("switch_company"),
                    icon: "fa-solid fa-building",
                    command: () => (this.showSwitchCompany = true),
                },
                {
                    label: this.$t("logout"),
                    icon: "fa-solid fa-right-from-bracket",
                    command: () => this.$auth0.logout(),
                },
            ],
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
        userInitials() {
            if (!this.user.first_name || !this.user.last_name) return "CC";
            return `${this.user.first_name.substring(
                0,
                1
            )}${this.user.last_name.substring(0, 1)}`;
        },
        auth0User() {
            return useAuth0().user;
        },
    },
    methods: {
        goTo(value) {
            this.$router.push(value);
        },
        toggle(event) {
            this.$refs.menu.toggle(event);
        },
    },
};
</script>
