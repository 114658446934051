<template>
    <div class="custom-metric__form-footer">
        <Button outlined @click="$emit('cancel')">{{ $t("cancel") }}</Button>
        <Button type="submit" form="input-form">
            {{
                selectedCustomMetric?.id ? $t("update") : $t("cm_create_button")
            }}
        </Button>
    </div>
</template>

<script>
import Button from "primevue/button";

export default {
    name: "MetricSidebarButtons",
    components: {
        Button,
    },
    props: {
        selectedCustomMetric: {
            type: Object,
            required: false,
            default: null,
        },
    },
};
</script>
