import http from "../http-common";

const api = {
    list: () => {
        return http.get(`/business-units`);
    },

    get: (businessUnitId) => {
        return http.get(`/business-unit/${businessUnitId}/details`);
    },

    add: (name, colour) => {
        return http.post("/business-unit/add", {
            name,
            colour,
        });
    },

    delete: (businessUnitId) => {
        return http.post(`/business-unit/${businessUnitId}/delete`);
    },

    update: (businessUnitId, name, colour) => {
        return http.post(`/business-unit/${businessUnitId}/update`, {
            name,
            colour,
        });
    },
};

export default api;
