<template>
    <div v-if="siteAccessArray.length > 0">
        <Badge
            class="badge--blue badge--noclick"
            :class="{
                'badge--red': siteAccessArray[0].deleted,
                'badge--blue': !siteAccessArray[0].deleted,
            }"
        >
            {{ siteAccessArray[0].name }}
        </Badge>
        <p
            v-if="siteAccessArray.length > 1"
            class="text-gray-500 light text-small ml-1 mb-0"
        >
            {{
                $t("team_function_text", {
                    length: siteAccessArray.length,
                })
            }}
        </p>
    </div>

    <Badge class="badge--blue badge--noclick" v-else>
        {{ $t("all_sites") }}
    </Badge>
</template>

<script setup lang="ts">
import Badge from "primevue/badge";
import { computed, type PropType } from "vue";
import { useStore } from "vuex";
const store = useStore();

const props = defineProps({
    invite: {
        type: Object as PropType<TInvite>,
        required: true,
    },
});

const siteAccessArray = computed(() => {
    const role_info = props.invite.role_info;
    if (role_info === null) return [];
    if (!role_info.sites) return [];

    const sites = role_info.sites.map((x) => {
        return (
            store.getters.getSite(x) ||
            store.getters.getDeletedSite(x) || {
                name: `Unknown (${x})`,
            }
        );
    });
    return sites;
});
</script>
