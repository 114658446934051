<template>
    <div>
        <Card v-if="$can('read', 'period')">
            <template #title>
                <h2>{{ $t("company_period_settings") }}</h2>
            </template>
            <template #content>
                <div class="grid">
                    <div class="col-3">
                        <h4>{{ $t("companyGeneral_periods") }}</h4>
                    </div>
                    <div class="col-9">
                        <company-revenue-table></company-revenue-table>
                    </div>
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import CompanyRevenueTable from "@/components/company/period-settings/CompanyRevenueTable.vue";
import Card from "primevue/card";

export default {
    components: { CompanyRevenueTable, Card },
};
</script>
