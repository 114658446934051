import http from "../http-common";

const api = {
    listAllCategories: () => {
        return http.get("/emission-categories/get");
    },

    getSelected: () => {
        return http.get("/emission-categories/get-selected");
    },
};

export default api;
