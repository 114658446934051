// MUTATIONS
export const MUTATE_SET_COMPANY = "MUTATE_SET_COMPANY";
export const MUTATE_SET_COMPANIES = "MUTATE_SET_COMPANIES";
export const MUTATE_SET_USER = "MUTATE_SET_USER";
export const MUTATE_THROW_ERROR = "MUTATE_THROW_ERROR";
export const MUTATE_RELOAD_EMISSION_DATA_LIST =
    "MUTATE_RELOAD_EMISSION_DATA_LIST";
export const MUTATE_RELOAD_ALL_DATA_LISTS = "MUTATE_RELOAD_ALL_DATA_LISTS";
export const MUTATE_FILTERS = "MUTATE_FILTERS";
export const MUTATE_SET_PERIODS = "MUTATE_SET_PERIODS";
export const MUTATE_SET_TOAST = "MUTATE_SET_TOAST";
export const MUTATE_RESET_FILTERS = "MUTATE_RESET_FILTERS";
export const MUTATE_SET_SORT = "MUTATE_SET_SORT";
export const MUTATE_SET_DATA = "MUTATE_SET_DATA";
export const MUTATE_SELECT_SOURCE = "MUTATE_SELECT_SOURCE";
export const MUTATE_SELECT_ALL_SOURCES = "MUTATE_SELECT_ALL_SOURCES";
export const MUTATE_DESELECT_ALL_SOURCES = "MUTATE_DESELECT_ALL_SOURCES";
export const MUTATE_MOVE = "MUTATE_MOVE";
export const MUTATE_SHOW_LOADER = "MUTATE_SHOW_LOADER";
export const MUTATE_SET_PRELOADED = "MUTATE_SET_PRELOADED";

// ACTIONS
export const SET_COMPANY = "SET_COMPANY";
export const SET_COMPANIES = "SET_COMPANIES";
export const SET_USER = "SET_USER";
export const THROW_ERROR = "THROW ERROR";
export const RELOAD_EMISSION_DATA_LIST = "RELOAD_EMISSION_DATA_LIST";
export const RELOAD_ALL_DATA_LISTS = "RELOAD_ALL_DATA_LISTS";
export const SET_FILTER = "SET_FILTER";
export const SET_PERIODS = "SET_PERIODS";
export const SET_TOAST = "SET_TOAST";
export const RESET_FILTERS = "RESET_FILTERS";
export const SET_SORT = "SET_SORT";
export const SET_DATA = "SET_DATA";
export const POPULATE = "POPULATE";
export const SELECT_SOURCE = "SELECT_SOURCE";
export const SELECT_ALL_SOURCES = "SELECT_ALL_SOURCES";
export const DESELECT_ALL_SOURCES = "DESELECT_ALL_SOURCES";
export const MOVE = "MOVE";
export const SHOW_LOADER = "SHOW_LOADER";
