<template>
    <div>
        <div d3-chart :id="chartKey" v-show="!hideGraph"></div>
        <div v-if="hideGraph" class="ml-2 text-gray-500 text-tiny">
            {{ $t("charts_no_data") }}
        </div>
    </div>
</template>

<script>
import * as d3 from "d3";

export default {
    props: {
        data: {
            type: Array,
            required: true,
        },
        labelKey: {
            type: String,
            required: true,
        },
        idKey: {
            type: String,
            required: true,
        },
        chartKey: {
            type: String,
            required: false,
            default: "d3-chart",
        },
        barStyle: {
            type: String,
            required: false,
            default: "bar__blue",
        },
        barPadding: {
            type: Number,
            required: false,
            default: 0.95,
        },
        highlighted: {
            type: [Number, String],
            required: false,
            default: null,
        },
    },
    data() {
        return {
            svg: null,
            xScale: null,
            yScale: null,
            graph: null,
            height: 300,
            width: 1000,
        };
    },
    computed: {
        highestDataPoint() {
            const emissions = this.data.map((x) => x.emissions);
            const highest = Math.max(...emissions);
            return highest + (highest / 100) * 12;
        },
        hideGraph() {
            let emissions = 0;
            this.data.forEach((item) => {
                emissions += item.emissions;
            });

            return emissions === 0;
        },
    },
    mounted() {
        this.generateChart();
    },
    methods: {
        generateChart() {
            this.svg = d3
                .select("#" + this.chartKey)
                .append("svg")
                .attr("viewBox", `0 0 ${this.width} ${this.height}`);

            this.yScale = d3
                .scaleLinear()
                .rangeRound([300, 0])
                .domain([0, this.highestDataPoint]);
            this.xScale = d3
                .scaleBand()
                .range([920, 0])
                .padding(this.barPadding)
                .domain(this.data.map((x) => x[this.idKey]));

            this.svg
                .append("g")
                .attr("transform", `translate(50, 280)`)
                .attr("class", "horizontal-ticks__text")
                .call(
                    d3
                        .axisBottom(this.xScale)
                        .tickSize(0)
                        .tickFormat((i, d) => this.data[d][this.labelKey])
                );

            this.svg
                .append("g")
                .attr("transform", "translate(50, -20)")
                .call(
                    d3
                        .axisLeft(this.yScale)
                        .tickSize(-920)
                        .tickFormat(d3.format("~s"))
                );

            this.graph = this.svg
                .selectAll(".bar")
                .data(this.data)
                .enter()
                .append("rect")
                .attr("class", (data) => {
                    let classes = `bar ${this.barStyle} bar__vertical`;
                    if (this.highlighted === null) return classes;

                    if (data.period_id && data.period_id === +this.highlighted)
                        return classes;
                    else return `${classes} bar__faded`;
                })
                .classed(`bar ${this.barStyle} bar__vertical`, true)
                .attr("width", this.xScale.bandwidth())
                .attr("height", (data) => 300 - this.yScale(data.emissions))
                .attr("rx", 1.5)
                .attr("x", (data) => this.xScale(data[this.idKey]))
                .attr("y", (data) => this.yScale(data.emissions))
                .on("mouseover", (e, data) => this.addHoverData(e, data))
                .on("mouseout", (e) => this.addHoverData(e, null))
                .on("mousemove", (e, data) => this.addHoverData(e, data))
                .on("mouseup", (e, data) => this.$emit("bar-clicked", data));

            this.svg.selectAll("g .domain").attr("stroke", "transparent");

            this.svg.selectAll("g .tick").attr("stroke-dasharray", "3,3");

            this.svg
                .selectAll(".horizontal-ticks__text .tick text")
                .attr("textLength", (d, i, j) => this.checkLabelWidth(j[i]))
                .attr("lengthAdjust", "spacing");

            this.svg.selectAll("g .tick line").attr("stroke", "#adb5bd");
        },
        addHoverData(e, data) {
            const coords = d3.pointer(e);
            this.svg.selectAll(".hover-data, .hover-data__background").remove();

            if (data !== null) {
                const hoverbox = this.svg
                    .append("rect")
                    .attr(
                        "transform",
                        `translate(${coords[0]}, ${
                            coords[1] > this.height / 2
                                ? coords[1] - 55
                                : coords[1] - 5
                        })`
                    )
                    .attr("fill", "white")
                    .attr("height", 30)
                    .attr("stroke", "black")
                    .attr("rx", 5)
                    .classed("hover-data__background", true);
                const hovertext = this.svg
                    .append("text")
                    .attr(
                        "transform",
                        `translate(${coords[0] + 10}, ${
                            coords[1] > this.height / 2
                                ? coords[1] - 35
                                : coords[1] + 15
                        })`
                    )
                    .classed("hover-data", true)
                    .text(
                        `${this.$checkDecimals(data.emissions)} ${data.unit}`
                    );
                const hovertextWidth = hovertext._groups[0][0].getBBox().width;
                hoverbox.attr("width", hovertextWidth + 20);
            }
        },
        checkLabelWidth(label) {
            if (!label) return "";
            const width = label.getBBox().width;

            const graphWidth = 800 / this.data.length;

            if (width < graphWidth) return width;
            return graphWidth;
        },
    },
};
</script>
