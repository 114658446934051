<template>
    <Card>
        <template #title>
            <h2>{{ $t("company_button_data_configuration") }}</h2>
        </template>
        <template #content>
            <div class="grid">
                <div class="col-3">
                    <h4>{{ $t("input_value_threshold") }}</h4>
                    <p class="text-xs text-gray-500 mt-2">
                        {{ $t("input_value_threshold_description") }}
                    </p>
                </div>
                <div class="col-9">
                    <CompanyValueTreshold
                        :value-threshold-object="valueThreshold"
                        @update:valueTreshold="
                            updateCompanyConfig(
                                'input_value_threshold',
                                +$event
                            )
                        "
                    />
                </div>
            </div>
            <div class="grid">
                <div class="col-3">
                    <h4>{{ $t("consolidation_approach") }}</h4>
                    <p class="text-xs text-gray-500 mt-2">
                        {{ $t("consolidation_approach_description") }}
                    </p>
                </div>
                <div class="col-9">
                    <CompanyConsolidationApproach
                        :consolidation-approach-object="consolidationApproach"
                        @update:consolidationApproach="
                            updateCompanyConfig('data_consolidation', $event)
                        "
                    />
                </div>
            </div>
        </template>
    </Card>
</template>

<script>
import CompanyConsolidationApproach from "./data-configuration/CompanyConsolidationApproach.vue";
import CompanyValueTreshold from "./data-configuration/CompanyValueTreshold.vue";
import Card from "primevue/card";
import * as types from "@/store/types";

import companyConfigApi from "@/api/company-config";

export default {
    components: { CompanyConsolidationApproach, CompanyValueTreshold, Card },
    data() {
        return {
            config: [],
        };
    },
    computed: {
        valueThreshold() {
            return this.config.find(
                (item) => item.configuration_key === "input_value_threshold"
            );
        },
        consolidationApproach() {
            return this.config.find(
                (item) => item.configuration_key === "data_consolidation"
            );
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.getCompanyConfig();
        },
        async getCompanyConfig() {
            try {
                const response = await companyConfigApi.getCompanyConfig();
                this.config = response.data.data;
            } catch (error) {
                console.log(error);
            }
        },
        async updateCompanyConfig(key, value) {
            const configItem = this.config.find(
                (item) => item.configuration_key === key
            );

            if (configItem) configItem.configuration_value = value;

            try {
                await companyConfigApi.updateCompanyConfigItem(
                    configItem?.id ?? undefined,
                    key,
                    value
                );
                this.getCompanyConfig();
                this.$store.dispatch(
                    types.SET_TOAST,
                    this.$t("config_updated")
                );
            } catch (error) {
                console.log(error);
            }
        },
    },
};
</script>
