import store from "@/store";

export const checkFeatures = (item) => {
    const user = store.state.user;
    return user.features.includes(item);
};
// Create the Vue plugin install feature
const has_feature = {
    install: (app) => {
        app.config.globalProperties.$has_feature = (item) =>
            checkFeatures(item);
    },
};

export default has_feature;
