<template>
    <div>
        <div
            class="menu-select__item"
            :class="
                category.id === selectedCategory
                    ? 'menu-select__item--selected'
                    : ''
            "
            v-for="category in categories"
            :key="category.id"
            @click="handleInput(category.id)"
        >
            {{ $t(category.name) }}
        </div>
    </div>
</template>

<script>
import * as types from "@/store/types";

import { mapState } from "vuex";

export default {
    computed: {
        ...mapState({
            categories: (state) => state.data.categories,
            selectedCategory: (state) => state.filters.category,
        }),
    },
    methods: {
        handleInput(id) {
            this.$store.dispatch(types.SET_FILTER, { category: id });
            this.$emit("selected");
        },
    },
};
</script>
