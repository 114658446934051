import { useAuth0 } from "@auth0/auth0-vue";

export default {
    data() {
        return {};
    },
    computed: {
        auth() {
            return useAuth0();
        },
    },
    methods: {
        // Log the user in
        login() {
            this.auth.loginWithRedirect();
        },
        // Log the user out
        logout() {
            this.auth.logout({
                returnTo: window.location.origin,
            });
        },
    },
};
