import http from "../http-common";

const api = {
    add: (params) => {
        return http.post("/metrics", params);
    },
    list: () => {
        return http.get("/metrics");
    },

    update: (id, params) => {
        return http.put(`/metrics/${id}`, params);
    },

    delete: (id) => {
        return http.delete(`/metrics/${id}`);
    },

    getCustomMetricValues: (metricId, periodId, queryInput = {}) => {
        return http.get(
            `/metrics/${metricId}/period/${periodId}/values`,
            queryInput
        );
    },
    createCustomMetricValue: (metricId, params) => {
        return http.post(`/metrics/${metricId}/values`, params);
    },
    updateCustomMetricValue: (metricId, params, valueId) => {
        return http.put(`/metrics/${metricId}/values/${valueId}`, params);
    },

    //Attachments
    listAttachments: (recordType, valueId) => {
        return http.get(`/attachment/${recordType}/${valueId}`);
    },

    uploadAttachment: (recordType, valueId, params) => {
        return http.post(`/attachment/${recordType}/${valueId}`, params, {
            headers: { "Content-Type": "multipart/form-data" },
        });
    },

    downloadAttachment: (recordType, valueId, attachmentId) => {
        return http.get(
            `/attachment/${recordType}/${valueId}/${attachmentId}/download`,
            {
                responseType: "blob",
            }
        );
    },

    deleteAttachment: (recordType, valueId, attachmentId) => {
        return http.delete(
            `/attachment/${recordType}/${valueId}/${attachmentId}`
        );
    },
};

export default api;
