export default {
    methods: {
        openModal() {
            this.$bvModal.show("modal");
            this.$emit("modal-open", true);
            this.toggle();
        },
        closeModal() {
            this.$bvModal.hide("modal");
            this.$emit("modal-open", false);
        },
        minimizeModal() {
            this.$bvModal.hide("modal");
            this.$emit("modal-open", false);
            this.open();
        },
        handleCreate(bvModalEvent) {
            bvModalEvent.preventDefault();
            this.createNewMetric();
            this.$emit("modal-open", false);
        },
        handleClose() {
            this.toggle();
            this.$emit("hide");
            this.minimizeModal();
            this.$emit("modal-open", false);
            this.data = null;
        },
    },
};
