<template>
    <div class="relative">
        <div class="icon-select__button" @click="showPopover = !showPopover">
            <i
                :class="`${icontype} ${localSelectedIcon} ${
                    localSelectedIcon === defaultIconClass
                        ? 'icon-select__button--default'
                        : 'icon-select__button--selected'
                }`"
            ></i>
        </div>
        <div
            class="icon-select__popover"
            :class="showPopover ? 'icon-select__popover--visible' : ''"
        >
            <span class="flex align-items-center justify-content-between"
                ><span>{{ $t("icons") }}</span>
                <span @click="removeIcon" class="text-gray-600">
                    {{ $t("remove") }}
                </span></span
            >

            <div class="icon-grid">
                <i
                    v-for="(icon, i) in iconSelection"
                    :key="`${i}icon`"
                    :class="`${icontype} ${icon}`"
                    class="icon-grid__icon"
                    @click="handleIconSelect(icon)"
                ></i>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CcSelectIcon",
    props: {
        selectedIcon: {
            type: String,
            required: false,
            default: "",
        },
        showDefaultChoices: {
            type: Boolean,
            required: false,
            default: true,
        },
        customIcons: {
            type: Array,
            required: false,
            default: () => [],
        },
        icontype: {
            type: String,
            required: false,
            default: "far",
        },
    },
    data() {
        return {
            defaultIconChoices: [
                "fa-megaphone",
                "fa-send",
                "fa-money-bill",
                "fa-users",
                "fa-briefcase",
                "fa-shield",
                "fa-map",
                "fa-wrench",
                "fa-book",
                "fa-gift",
                "fa-save",
                "fa-id-card",
                "fa-star",
                "fa-camera",
                "fa-globe",
            ],
            showPopover: false,
            localSelectedIcon: this.selectedIcon || "fa-table-cells-large",
            defaultIconClass: "fa-table-cells-large",
        };
    },
    computed: {
        iconSelection() {
            const iconSelection = [];
            if (this.showDefaultChoices) {
                iconSelection.push(...this.defaultIconChoices);
            }
            return iconSelection.concat(this.customIcons);
        },
    },
    methods: {
        handleIconSelect(icon) {
            this.localSelectedIcon = icon;
            this.$emit("select-icon", icon);
            this.showPopover = false;
        },
        removeIcon() {
            this.localSelectedIcon = this.defaultIconClass;
            this.$emit("select-icon", "");
            this.showPopover = false;
        },
    },
};
</script>
