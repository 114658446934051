<template>
    <div>
        <b-container fluid>
            <ControlBar :hide="['sort']" :hideFilters="['search']">
                <template v-slot:title>
                    <insight-header
                        :items="bus"
                        :title="headerTitle"
                        @item-selected="
                            selectBU({ business_unit_id: $event.id })
                        "
                    ></insight-header>
                </template>
            </ControlBar>

            <transition name="slide-down" mode="out-in">
                <template v-if="buFilter && buFilter.length === 0">
                    <bu-charts
                        class="mt-4"
                        :bus="bus"
                        @bu-clicked="selectBU"
                    ></bu-charts>
                </template>
                <template v-else>
                    <bu-details class="mt-4"></bu-details>
                </template>
            </transition>
        </b-container>
    </div>
</template>

<script>
import InsightHeader from "@/components/insights/InsightHeader";

import BuCharts from "@/components/insights/charts/BuCharts";
import BuDetails from "@/components/insights/charts/BuDetails";

import InsightsWrapperMixin from "@/components/insights/mixins/InsightsWrapperMixin";
import ControlBar from "../../components/controls/ControlBar.vue";

import { mapState } from "vuex";

export default {
    mixins: [InsightsWrapperMixin],
    components: {
        InsightHeader,
        BuCharts,
        BuDetails,
        ControlBar,
    },
    data() {
        return {
            emptyBu: [
                { name: this.$t("all_business_units"), id: null },
                { name: this.$t("unassigned"), id: -1 },
            ],
        };
    },
    computed: {
        ...mapState({
            availableBus: (state) => state.data.businessUnits,
            buFilter: (state) => state.filters.businessUnits,
        }),
        headerTitle() {
            if (!this.buFilter || this.buFilter.length === 0)
                return this.$t("all_business_units");
            if (this.buFilter.length === 1)
                return this.bus.find((x) => x.id === this.buFilter[0]).name;
            else return this.$t("insights_businessUnits_headerTitle_Multiple");
        },
        bus() {
            return [...this.emptyBu, ...this.availableBus];
        },
    },
    methods: {
        selectBU(bu) {
            if (!bu || bu.business_unit_id === null)
                return this.setFilter({ businessUnits: [] });
            this.setFilter({ businessUnits: [bu.business_unit_id] });
        },
    },
};
</script>
