<template>
    <div>
        <div v-if="tagsAccessArray.length > 1">
            <Badge class="badge--blue badge--noclick">
                {{ tagsAccessArray[0].title }}
            </Badge>
            <p
                v-if="tagsAccessArray.length > 1"
                class="text-gray-500 light text-small ml-1 mb-0"
            >
                {{ $t("and") }}
                {{ tagsAccessArray.length - 1 }}
                {{ $t("more") }}
            </p>
        </div>

        <Badge class="badge--blue badge--noclick" v-else>{{
            $t("all_tags")
        }}</Badge>
    </div>
</template>

<script setup lang="ts">
import Badge from "primevue/badge";
import { computed, type PropType } from "vue";
import { useStore } from "vuex";
const store = useStore();

const props = defineProps({
    invite: {
        type: Object as PropType<TInvite>,
        required: true,
    },
});

const tagsAccessArray = computed(() => {
    const role_info = props.invite.role_info;
    if (role_info === null) return [];
    if (!role_info.tag_ids) return [];

    const tags = role_info.tag_ids.map((x) => {
        return store.getters.getTag(x);
    });
    return tags;
});
</script>
