<template>
    <div>
        <Card v-if="$can('read', 'company')">
            <template #title>
                <h2>{{ $t("company_info") }}</h2>
            </template>
            <template #content>
                <CompanyGeneralHeader
                    :mode="edit ? 'edit' : 'view'"
                    :savingStatus="savingStatus"
                    :saving="saving"
                    :edit="edit"
                    @edit="handleEdit"
                    @save="handleSave"
                />

                <Transition name="fade" mode="out-in">
                    <CompanyGeneralInfo
                        v-if="!edit"
                        :company="company"
                    ></CompanyGeneralInfo>

                    <CompanyGeneralForm
                        v-else
                        :company="editingCompany"
                        @update:company="editingCompany = $event"
                        @refresh="refreshCompany"
                    ></CompanyGeneralForm>
                </Transition>
            </template>
        </Card>
    </div>
</template>

<script>
import Card from "primevue/card";
import companyApi from "@/api/company.js";
import CompanyGeneralHeader from "./general/CompanyGeneralHeader.vue";
import CompanyGeneralForm from "./general/CompanyGeneralForm.vue";
import CompanyGeneralInfo from "./general/CompanyGeneralInfo.vue";
import * as types from "@/store/types.js";

import { mapState } from "vuex";

export default {
    components: {
        Card,
        CompanyGeneralHeader,
        CompanyGeneralForm,
        CompanyGeneralInfo,
    },
    data() {
        return {
            edit: false,
            editingCompany: {},
            saving: false,
            savingStatus: "waiting",
            fileSaving: false,
            fileSavingStatus: "waiting",
            fileSavingError: null,
            imgFile: null,
        };
    },
    computed: {
        ...mapState({
            company: (state) => state.company,
        }),
    },
    methods: {
        handleEdit() {
            this.editingCompany = { ...this.company };
            this.edit = true;
        },
        async handleSave() {
            this.saving = true;
            const {
                name,
                sector,
                address_line_1,
                address_line_2,
                postcode,
                city,
                country_code,
            } = this.editingCompany;
            const params = {
                name,
                sector,
                postcode,
                city,
                countryCode: country_code,
                addressLine1: address_line_1,
                addressLine2: address_line_2,
            };

            try {
                await companyApi.updateDetails(params);
                this.refreshCompany();
                this.$amplitude.logEvent("UPDATED_COMPANY");
            } catch (error) {
                console.log(error);
            } finally {
                this.edit = false;
                this.saving = false;
                this.savingStatus = "success";
                this.editingCompany = {};
            }
        },
        async refreshCompany() {
            try {
                const response = await companyApi.getDetails();
                const result = response.data;
                this.$store.dispatch(types.SET_COMPANY, result);
            } catch (error) {
                console.log(error);
            }
        },
    },
};
</script>
