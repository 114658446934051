const getLocation = (href) => {
    const match = href.match(
        /^(https?:)\/\/(([^:/?#]*)(?::([0-9]+))?)([/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/
    );
    return (
        match && {
            href: href,
            protocol: match[1],
            host: match[2],
            hostname: match[3],
            port: match[4],
            pathname: match[5],
            search: match[6],
            hash: match[7],
        }
    );
};

export const getHost = () => {
    const url = "" + window.location;
    const location = getLocation(url);
    if (location && location.protocol && location.hostname) {
        const host = location.protocol + "//" + location.hostname;
        return host;
    }
};

export const getBaseURL = (api = true) => {
    let host = getHost();

    // Check the hostname based on environment
    if (process.env.NODE_ENV !== "production") {
        if (api) host = `${host}:8080`;
        else host = `${host}:8081`;
    }

    // If we (by default) want the API host, add /api here
    if (api) return `${host}/api`;

    // Otherwise just return the hostname
    return host;
};
