import http from "../http-common";

const api = {
    getCompanyConfig: () => {
        return http.get("/v2/configuration");
    },

    getCompanyConfigItem: (key: string) => {
        return http.get(`/v2/configuration/${key}`);
    },

    updateCompanyConfigItem: (
        config_id: number,
        config_key: string,
        config_value: number | string
    ) => {
        // We need to 1x create the config, so if we have no ID, do the create. Otherwise update
        if (!config_id) {
            return http.post("/v2/configuration", {
                configuration_key: config_key,
                configuration_value: config_value,
            });
        }

        return http.put(`/v2/configuration/${config_id}`, {
            configuration_key: config_key,
            configuration_value: config_value,
        });
    },

    deleteCompanyConfigItem: (config_id: number) => {
        return http.delete(`/v2/configuration/${config_id}`);
    },
};

export default api;
