<template>
    <Select
        v-model="selectedLanguage"
        :options="languageOption"
        optionLabel="text"
        optionValue="value"
        @update:modelValue="changeLanguage"
        variant="filled"
    >
    </Select>
</template>

<script>
import Select from "primevue/select";

export default {
    components: { Select },
    name: "LanguageSelector",

    data() {
        return {
            selectedLanguage:
                localStorage.getItem("locale") ?? this.$i18n.locale,
            languageOption: [
                { value: "en", text: "English" },
                { value: "de", text: "Deutsch" },
                { value: "es", text: "Español" },
                { value: "fr", text: "Français" },
            ],
        };
    },

    methods: {
        changeLanguage() {
            // Update the i18n locale
            this.$i18n.locale = this.selectedLanguage;
            localStorage.setItem("locale", this.selectedLanguage);
        },
    },
};
</script>
