<template>
    <Chip
        class="badge badge-pill"
        :style="getBadgeStyle(metricValue.business_unit_id)"
        :class="metricValue.business_unit_id === null ? 'badge--empty' : ''"
    >
        <span>{{ getBuBadgeText(metricValue) }}</span>
    </Chip>
</template>

<script>
import Chip from "primevue/chip";

export default {
    components: {
        Chip,
    },
    props: {
        metricValue: {
            type: Object,
            required: true,
        },
        businessUnits: {
            type: Array,
            required: true,
        },
    },
    methods: {
        getBadgeStyle(buId) {
            const bu = this.businessUnits.find((x) => x.id === buId);
            if (bu === undefined) return "";

            const colour = bu.colour;
            if (colour) {
                return `color: ${colour}; background-color: ${colour}10; box-shadow: none;`;
            }
        },
        getBuName(id) {
            if (!id) return;
            const bu = this.businessUnits.find((x) => x.id === id);

            if (bu) return bu.name;
            else return this.$t("al_unknown");
        },
        getBuBadgeText(metricValue) {
            const businessUnitId = metricValue.business_unit_id;
            const siteId = metricValue.site_id;

            if (!businessUnitId && !siteId)
                return this.$t("all_business_units");
            if (!businessUnitId && siteId) return this.$t("badge_no_bu");

            return (
                this.getBuName(businessUnitId) ?? this.$t("all_business_units")
            );
        },
    },
};
</script>
