<template>
    <div>
        <div
            class="menu-select__item"
            :class="
                selectedScopes.includes(scope.id)
                    ? 'menu-select__item--selected'
                    : ''
            "
            v-for="scope in scopes"
            :key="scope.id"
            @click="handleInput(scope.id)"
        >
            {{ scope.name }}
        </div>
    </div>
</template>

<script>
import * as types from "@/store/types";

import { mapState } from "vuex";

export default {
    data() {
        return {
            scopes: [
                { id: 1, name: this.$t("scope") + " 1" },
                { id: 2, name: this.$t("scope") + " 2" },
                { id: 3, name: this.$t("scope") + " 3" },
            ],
        };
    },
    computed: {
        ...mapState({
            selectedScopes: (state) => state.filters.scopes,
        }),
    },
    methods: {
        handleInput(id) {
            // Check if the scope has been selected already
            const scopeIndex = this.selectedScopes.findIndex((x) => x === id);

            // If it hasn't, add it to the list of selected scopes
            if (scopeIndex === -1) {
                const selectedScopes = [...this.selectedScopes, id];
                return this.$store.dispatch(types.SET_FILTER, {
                    scopes: selectedScopes,
                });
            }

            // If it has, remove it from the list of selected scopes
            const selectedScopes = [
                ...this.selectedScopes.slice(0, scopeIndex),
                ...this.selectedScopes.slice(scopeIndex + 1),
            ];
            this.$store.dispatch(types.SET_FILTER, {
                scopes: selectedScopes,
            });
        },
    },
};
</script>
