<template>
    <td
        class="pr-1"
        :class="sort === 'value' ? 'cc-table__column--highlight' : ''"
    >
        <span>{{ this.$checkDecimals(metricValue.value) }}</span>
    </td>
</template>

<script>
export default {
    props: {
        metricValue: {
            type: Object,
            required: true,
        },

        sort: {
            type: String,
            required: false,
        },
    },
};
</script>
