<template>
    <div>
        <div v-if="businessAccessArray.length > 0">
            <Badge class="badge--blue badge--noclick">
                {{ businessAccessArray[0].name }}
            </Badge>
            <p
                v-if="businessAccessArray.length > 1"
                class="text-gray-500 light text-small ml-1 mb-0"
            >
                {{ $t("and") }}
                {{ businessAccessArray.length - 1 }}
                {{ $t("more") }}
            </p>
        </div>

        <Badge class="badge--blue badge--noclick" v-else>
            {{ $t("all_business_units") }}
        </Badge>
    </div>
</template>

<script setup lang="ts">
import Badge from "primevue/badge";
import { computed, type PropType } from "vue";
import { useStore } from "vuex";
const store = useStore();

const props = defineProps({
    invite: {
        type: Object as PropType<TInvite>,
        required: true,
    },
});

const businessAccessArray = computed(() => {
    const role_info = props.invite.role_info;
    if (role_info === null) return [];
    if (!role_info.business_units) return [];

    const businesses = role_info.business_units.map((x) => {
        return store.getters.getBusinessUnit(x);
    });
    return businesses;
});
</script>
