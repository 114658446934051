<template>
    <div>
        <div
            v-for="site in availableSites"
            :key="site.id"
            @click="handleInput(site.id)"
            class="menu-select__item"
            :class="
                selectedSites.includes(site.id)
                    ? 'menu-select__item--selected'
                    : ''
            "
        >
            {{ site.name }}
        </div>
    </div>
</template>

<script>
import * as types from "@/store/types";

import { mapState } from "vuex";

export default {
    computed: {
        ...mapState({
            sites: (state) => state.data.sites,
            selectedSites: (state) => state.filters.sites,
            role_info: (state) => state.user.role_info,
        }),
        availableSites() {
            const selection = [...this.sites];
            if (!this.sitesLimited)
                selection.push({ name: this.$t("unassigned"), id: -1 });
            return selection;
        },
        sitesLimited() {
            return this.role_info?.sites?.length > 0;
        },
    },

    methods: {
        handleInput(id) {
            // Check if the site has been selected already
            const siteIndex = this.selectedSites.findIndex((x) => x === id);

            // If it hasn't, add it to the list of selected sites
            if (siteIndex === -1) {
                const selectedSites = [...this.selectedSites, id];
                return this.$store.dispatch(types.SET_FILTER, {
                    sites: selectedSites,
                });
            }

            // If it has, remove it from the list of selected sites
            const selectedSites = [
                ...this.selectedSites.slice(0, siteIndex),
                ...this.selectedSites.slice(siteIndex + 1),
            ];
            this.$store.dispatch(types.SET_FILTER, {
                sites: selectedSites,
            });
        },
    },
};
</script>
