<template>
    <div>
        <button
            class="cc-sidebar__hamburger"
            @click.prevent="showSideBar = true"
            v-show="!showSideBar"
        >
            <i class="fas fa-bars"></i>
        </button>
        <div
            class="cc-sidebar"
            :class="{ active: showSideBar }"
            @click="showSideBar = false"
        >
            <div class="cc-sidebar__inner">
                <div class="cc-sidebar__header">
                    <router-link to="/dashboard">
                        <img
                            :src="require('../../../assets/logo.svg')"
                            class="logo"
                            alt="Futureproofed"
                        />
                        <img
                            :src="require('../../../assets/ho-ho-ho.png')"
                            v-if="december"
                            class="logo__santa"
                            alt="Ho ho ho"
                        />
                    </router-link>
                </div>

                <div class="cc-sidebar__body">
                    <router-link
                        to="/dashboard"
                        exact
                        class="router-link"
                        v-if="$can('read', 'dashboard')"
                    >
                        <i class="far fa-home-lg"></i>
                        {{ $t("navigation_home") }}
                    </router-link>

                    <router-link
                        to="/company-metrics"
                        exact
                        class="router-link"
                        v-if="$can('read', 'metric')"
                    >
                        <i class="far fa-flag"></i>
                        {{ $t("navigation_custom_metrics") }}
                    </router-link>

                    <router-link to="/data-input" exact class="router-link">
                        <i class="far fa-tasks"></i>
                        {{ $t("navigation_emission_data") }}
                    </router-link>
                    <router-link
                        to="/insights"
                        class="router-link"
                        id="insights-target-v2"
                        v-if="$can('read', 'insights')"
                    >
                        <i class="far fa-chart-line-down"></i>
                        {{ $t("navigation_insights") }}
                    </router-link>
                    <router-link
                        v-if="false"
                        to="/reporting"
                        class="router-link"
                        id="reports-dashboard"
                    >
                        <i class="fa-light fa-file"></i>
                        {{ $t("reporting") }}
                    </router-link>
                    <!-- <b-popover
                        target="insights-target"
                        triggers="hover"
                        placement="left"
                        :show.sync="popoverShow"
                        custom-class="popover-insights"
                    >
                        <div
                            @click="handlePopoverClick('category')"
                            class="text-gray-500 router-link my-3 mx-2 cursor-pointer"
                        >
                            {{ $t("navigation_insights_ghg") }}
                        </div>
                        <div
                            @click="handlePopoverClick('sites')"
                            class="text-gray-500 router-link my-3 mx-2 cursor-pointer"
                        >
                            {{ $t("Sites") }}
                            {{ $t("navigation_insights_sites") }}
                        </div>
                        <div
                            @click="handlePopoverClick('businessunits')"
                            class="text-gray-500 router-link my-3 mx-2 cursor-pointer"
                        >
                            {{ $t("navigation_insights_bu") }}
                        </div>
                    </b-popover> -->

                    <router-link
                        to="/target"
                        exact
                        class="router-link"
                        v-if="$can('read', 'reduction_target')"
                    >
                        <i class="far fa-bullseye-arrow"></i>
                        {{ $t("navigation_targets") }}
                    </router-link>

                    <!-- <router-link to="/report" exact class="router-link">
                        <i class="fas fa-chart-line"></i>
                        Report
                    </router-link> -->
                </div>

                <div class="cc-sidebar__footer">
                    <Divider />
                    <router-link to="/company">
                        <Avatar
                            variant="primary"
                            :label="companyInitials"
                            :image="companyLogo"
                            class="cc-badge-yellow"
                            shape="circle"
                            size="large"
                        ></Avatar>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AuthMixin from "@/mixins/auth/AuthMixin";
import { mapState } from "vuex";
import Avatar from "primevue/avatar";
import Divider from "primevue/divider";

export default {
    name: "cc-side-bar",
    mixins: [AuthMixin],
    components: {
        Avatar,
        Divider,
    },
    props: {
        logo: {
            type: String,
            required: false,
            default: "",
        },
    },
    data() {
        return {
            showSideBar: false,
            switchCompany: false,
            popoverShow: false,
        };
    },
    computed: {
        ...mapState({
            company: (state) => state.company,
        }),
        companyInitials() {
            if (!this.company || !this.company.name) return "";
            return this.company.name.substring(0, 2);
        },
        companyLogo() {
            if (!this.company || !this.company.logo) return null;
            return this.company.logo;
        },
        december() {
            const now = new Date();
            return now.getMonth() === 11 && now.getDate() > 15;
        },
    },
    methods: {
        // handlePopoverClick(link) {
        //     this.popoverShow = false;
        //     this.$router.push(`/insights/${link}`);
        // },
    },
};
</script>
