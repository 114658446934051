import { getCountrySelectOptions, countryMapper } from "@/util/countries";
export default {
    data() {
        return {
            countrySelectOptions: [],
        };
    },
    created() {
        this.countrySelectOptions = getCountrySelectOptions();
    },
    computed: {
        extendedCountrySelectOptions() {
            let options = this.countrySelectOptions.slice(1);
            options.unshift(
                { value: "", text: "Global" },
                { value: "EU", text: "Europe" }
            );
            return options;
        },
    },
    methods: {
        getFullCountry(countryCode) {
            return countryMapper[countryCode];
        },
    },
};
