<template>
    <form @submit.prevent id="edit-company-form" class="cc-form">
        <div class="cc-form-group">
            <label for="company-name">{{ $t("label_name") }}</label>
            <CcInput
                id="company-name"
                v-model="localCompany.name"
                :placeholder="$t('label_name')"
            />
        </div>
        <div class="cc-form-group">
            <label for="company-sector">{{ $t("label_sector") }}</label>
            <CcInput
                id="company-sector"
                v-model="localCompany.sector"
                :placeholder="$t('label_sector')"
            />
        </div>
        <div class="cc-form-group">
            <label for="company-address1">{{ $t("label_address") }}</label>
            <CcInput
                id="company-address1"
                v-model="localCompany.address_line_1"
                :placeholder="$t('label_addres_line_1')"
            />
            <CcInput
                v-if="company.address_line_2"
                v-model="localCompany.address_line_2"
                :placeholder="$t('label_addres_line_2')"
            />
        </div>
        <div class="cc-form-group">
            <label for="company-city">{{ $t("label_city") }}</label>
            <CcInput
                id="company-city"
                v-model="localCompany.city"
                :placeholder="$t('label_city')"
            />
        </div>
        <div class="cc-form-group">
            <label for="company-postcode">{{ $t("label_zip") }}</label>
            <CcInput
                id="company-postcode"
                v-model="localCompany.postcode"
                :placeholder="$t('label_zip')"
            />
        </div>
        <div class="cc-form-group">
            <label for="company-country">{{ $t("label_country") }}</label>
            <CcSelect
                id="company-country"
                v-model="localCompany.country_code"
                :placeholder="$t('label_country')"
                :options="countrySelectOptions"
            />
        </div>
        <div class="cc-form-group">
            <label for="company-logo">{{ $t("label_logo") }}</label>

            <ProgressSpinner v-if="fileLoading" />
            <img v-else-if="company.logo" :src="company.logo" alt="logo" />

            <div class="flex justify-content-center gap-3 mt-4">
                <FileUpload
                    mode="basic"
                    @select="onFileSelect"
                    customUpload
                    auto
                    severity="secondary"
                    accept="image/*"
                    upload-label="Upload"
                    class="p-button-outlined"
                />
                <Button
                    label="Remove logo"
                    v-if="company.logo"
                    @click="handleRemoveLogo"
                    outlined
                    severity="danger"
                />
            </div>
        </div>
    </form>
</template>

<script setup lang="ts">
import { ref, type PropType, computed } from "vue";
import { getCountrySelectOptions } from "@/util/countries";
import FileUpload from "primevue/fileupload";
import Button from "primevue/button";
import ProgressSpinner from "primevue/progressspinner";
import companyApi from "@/api/company";

const props = defineProps({
    company: {
        type: Object as PropType<TCompany>,
        required: true,
    },
});

const emit = defineEmits(["update:company", "refresh"]);
const fileLoading = ref(false);

const countrySelectOptions = ref(getCountrySelectOptions());
const localCompany = computed({
    get: () => props.company,
    set: (value) => {
        emit("update:company", value);
    },
});

const onFileSelect = async (event) => {
    fileLoading.value = true;

    const image = event.files[0];
    const formData = new FormData();
    formData.append("image", image);

    try {
        const response = await companyApi.uploadLogo(formData);
        localCompany.value.logo = response.data;
    } catch (error) {
        console.log(error);
    } finally {
        fileLoading.value = false;
    }
};

const handleRemoveLogo = async () => {
    try {
        await companyApi.removePhoto();
        localCompany.value.logo = "";
        emit("refresh");
    } catch (error) {
        console.log(error);
    }
};
</script>
