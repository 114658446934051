<template>
    <transition-group name="badge-animation" tag="div">
        <Chip
            v-for="scope in scopes"
            :key="scope"
            class="badge badge--blue mr-2 badge--close badge-animation-item"
            @click="deleteFilter(scope)"
        >
            {{ `Scope ${scope}` }}
        </Chip>
    </transition-group>
</template>

<script>
import * as types from "@/store/types";
import Chip from "primevue/chip";

export default {
    components: {
        Chip,
    },
    props: {
        filters: {
            type: Object,
            required: true,
        },
    },
    computed: {
        scopes() {
            if (!this.filters.scopes) return [];
            return this.filters.scopes;
        },
    },
    methods: {
        deleteFilter(scope) {
            // Find the scope index
            const scopeIndex = this.filters.scopes.findIndex(
                (x) => x === scope
            );

            // And remove the scope
            const selectedScopes = [
                ...this.filters.scopes.slice(0, scopeIndex),
                ...this.filters.scopes.slice(scopeIndex + 1),
            ];
            this.$store.dispatch(types.SET_FILTER, {
                scopes: selectedScopes,
            });
        },
    },
};
</script>
