<template>
    <div>
        <b-container fluid>
            <ControlBar :hide="['sort']" :hideFilters="['search']">
                <template v-slot:title>
                    <insight-header
                        :items="sites"
                        :title="headerTitle"
                        @item-selected="selectSite({ site_id: $event.id })"
                    ></insight-header>
                </template>
            </ControlBar>

            <transition name="slide-down" mode="out-in">
                <template v-if="siteFilter && siteFilter.length === 0">
                    <sites-charts
                        class="mt-4"
                        :sites="sites"
                        @site-clicked="selectSite"
                    ></sites-charts>
                </template>
                <template v-else>
                    <sites-details
                        class="mt-4"
                        :selectedSite="selectedSite"
                    ></sites-details>
                </template>
            </transition>
        </b-container>
    </div>
</template>

<script>
import InsightHeader from "@/components/insights/InsightHeader";

import SitesCharts from "@/components/insights/charts/SitesCharts";
import SitesDetails from "@/components/insights/charts/SitesDetails";

import InsightsWrapperMixin from "@/components/insights/mixins/InsightsWrapperMixin";
import ControlBar from "../../components/controls/ControlBar.vue";

import { mapState } from "vuex";

export default {
    name: "InsightsSites",
    mixins: [InsightsWrapperMixin],
    components: {
        InsightHeader,
        SitesCharts,
        SitesDetails,
        ControlBar,
    },
    data() {
        return {
            emptySite: [
                { name: this.$t("all_sites"), id: null },
                { name: this.$t("unassigned"), id: -1 },
            ],
        };
    },
    computed: {
        ...mapState({
            availableSites: (state) => state.data.sites,
            siteFilter: (state) => state.filters.sites,
        }),
        headerTitle() {
            if (!this.siteFilter || this.siteFilter.length === 0)
                return this.$t("all_sites");
            if (this.siteFilter.length === 1)
                return this.sites.find((x) => x.id === this.siteFilter[0]).name;
            else return this.$t("insights_sites_headerTitle_Multiple");
        },
        sites() {
            return [...this.emptySite, ...this.availableSites];
        },
        selectedSite() {
            if (!this.site) return {};
            return this.sites.find((x) => x.id === this.site);
        },
    },
    methods: {
        selectSite(site) {
            if (!site || site.site_id === null)
                return this.setFilter({ sites: [] });
            this.setFilter({ sites: [site.site_id] });
        },
    },
};
</script>
