<template>
    <Dialog
        :header="title"
        v-model:visible="localShowModal"
        modal
        :style="{ width: '50%' }"
    >
        <form @submit.prevent="handleSubmit" class="cc-form" id="bu-form">
            <div class="cc-form-group">
                <label for="bu-name">
                    {{ $t("label_name") }}
                </label>
                <CcInput
                    id="bu-name"
                    v-model="localValue.name"
                    required
                ></CcInput>
            </div>
            <div class="cc-form-group">
                <label for="bu-colour">
                    {{ $t("label_colour") }}
                </label>
                <div>
                    <div
                        class="colour-preview shadow-sm"
                        :class="
                            localValue.colour === colour
                                ? 'colour-preview--selected'
                                : ''
                        "
                        v-for="colour in colours"
                        :key="colour"
                        :style="`background-color: ${colour};`"
                        @click="colourPicked(colour)"
                    ></div>
                </div>
            </div>
        </form>
        <div class="row mt-4">
            <div class="col">
                <Badge
                    class="badge badge-pill"
                    :style="`color: ${localValue.colour}; background-color: ${localValue.colour}10;`"
                >
                    <span>{{
                        localValue.name === ""
                            ? $t("enter_value")
                            : localValue.name
                    }}</span>
                </Badge>
            </div>
        </div>

        <template #footer>
            <div class="flex justify-content-between gap-2">
                <Button
                    type="button"
                    :label="$t('cancel')"
                    @click="localShowModal = false"
                    outlined
                />
                <Button type="submit" :label="$t('save')" form="bu-form" />
            </div>
        </template>
    </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";
import Badge from "primevue/badge";
import Button from "primevue/button";

export default {
    components: {
        Dialog,
        Badge,
        Button,
    },
    props: {
        modelValue: {
            type: Object,
            required: true,
        },
        showModal: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            bu: {
                name: "",
                colour: "",
            },
            colours: [
                "#01CAAB",
                "#FFD23F",
                "#EE4266",
                "#1C4577",
                "#D0CFEC",
                "#08B1C7",
                "#F7D1CD",
                "#B87B99",
                "#1EAE47",
                "#959537",
            ],
        };
    },
    computed: {
        localShowModal: {
            get() {
                return this.showModal;
            },
            set() {
                this.$emit("close");
            },
        },
        localValue: {
            get() {
                return this.modelValue;
            },
            set(val) {
                this.$emit("update:modelValue", val);
            },
        },
        title() {
            return this.modelValue?.id
                ? this.$t("companyBusinessUnits_edit")
                : this.$t("companyBusinessUnits_create");
        },
    },
    methods: {
        colourPicked(colour) {
            this.localValue.colour = colour;
        },
        handleSubmit() {
            this.$emit("save", this.localValue);
        },
    },
};
</script>
