import http from "../http-common";

const api = {
    getRegisterInfo: (token) => {
        return http.get(`/invite?token=${token}`);
    },

    completeRegistration: (user, token) => {
        return http.post(`/invite`, { user, token });
    },

    createInvite: (user, message) => {
        return http.post(`company/invite`, { user, message });
    },

    updateInvite: (id, body) => {
        return http.post(`company/invite/${id}/update`, body);
    },

    getOpenInvites: (includeExpired = false) => {
        return http.get(`/invites?includeExpired=${includeExpired}`);
    },

    revokeInvite: (id) => {
        return http.post(`/company/invite/${id}/revoke`);
    },
};

export default api;
