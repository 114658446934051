import { createI18n } from "vue-i18n";
import de from "../../../locales/de.json";
import en from "../../../locales/en.json";
import fr from "../../../locales/fr.json";
import nl from "../../../locales/nl.json";
import es from "../../../locales/es.json";

export const i18n = createI18n({
    locale: localStorage.getItem("locale") ?? "en",
    fallbackLocale: "en",
    messages: {
        en,
        fr,
        nl,
        de,
        es,
        // Add more locales here
    },
    missing: (locale, key, vm) => {
        console.log(`WARNING: Missing translation for ${key} in ${locale}`);
    },
});
