<template>
    <!-- FORM -->
    <form @submit.prevent="handleFormSubmit">
        <!--form badges -->
        <div class="flex gap-4">
            <span class="flex flex-column gap-2">
                <label for="site">{{ $t("label_site") }}</label>
                <Chip
                    id="site"
                    variant="primary"
                    x
                    class="badge badge--blue badge--noclick mb-3"
                    v-if="localData.site_id"
                >
                    {{ getSite(localData.site_id) }}
                </Chip>
                <Chip
                    id="site"
                    variant="primary"
                    class="badge mb-3"
                    v-else-if="localData.site_id === null"
                >
                    {{ $t("badge_no_site") }}
                </Chip>
            </span>

            <span class="flex flex-column gap-2">
                <label for="bu">{{ $t("label_bu") }}</label>
                <Chip
                    id="bu"
                    class="badge badge--blue badge--noclick mb-3"
                    v-if="localData.business_unit_id"
                >
                    {{ getBu(localData.business_unit_id) }}
                </Chip>
                <Chip
                    id="site"
                    class="badge mb-3"
                    v-else-if="localData.business_unit_id === null"
                >
                    {{ $t("badge_no_bu") }}
                </Chip>
            </span>
        </div>

        <div class="flex flex-column gap-4">
            <!-- value input -->
            <div class="flex flex-column gap-2">
                <label for="value">{{ $t("label_value") }}</label>
                <div class="flex align-items-center gap-2">
                    <div class="flex-grow-1 w-full">
                        <IconField class="w-full flex-grow-1">
                            <InputText
                                id="value"
                                type="text"
                                :placeholder="$t('enter_metric_value')"
                                v-model="localData.value"
                                @blur="handleFormSubmit('value')"
                                class="w-full"
                                appendText
                                :invalid="!valueState"
                                :disabled="periodLocked"
                            ></InputText>
                            <InputIcon>{{ unitDetails }}</InputIcon>
                        </IconField>
                    </div>
                    <saving-status-loader
                        :savedStatus="savedStatus.value"
                        :saving="saving.value"
                    />
                </div>
            </div>
            <!-- description input -->
            <div class="flex flex-column gap-2">
                <label for="description">{{ $t("label_comments") }}</label>
                <div class="flex align-items-center gap-2">
                    <Textarea
                        id="description"
                        v-model="localData.description"
                        :placeholder="$t('enter_metric_description')"
                        rows="5"
                        @blur="handleFormSubmit('description')"
                        :state="descriptionState"
                        class="flex-grow-1"
                        :disabled="periodLocked"
                    ></Textarea>
                    <saving-status-loader
                        :savedStatus="savedStatus.description"
                        :saving="saving.description"
                    />
                </div>
            </div>
        </div>

        <MetricAttachments
            :customMetricValueId="metricValue.id"
            :period-locked="periodLocked"
            class="mt-4"
        />
    </form>
</template>

<script>
import customMetricsApi from "@/api/custom_metrics";
import SavingStatusLoader from "@/components/elements/SavingStatusLoader";
import * as types from "@/store/types";
import Chip from "primevue/chip";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import { mapState } from "vuex";
import MetricAttachments from "./MetricAttachments.vue";

const newForm = {
    file: null,
    value: null,
    description: "",
    business_unit_id: null,
    site_id: null,
    period_id: null,
};

export default {
    name: "MetricValueDataInput",
    components: {
        SavingStatusLoader,
        Chip,
        InputText,
        Textarea,
        IconField,
        InputIcon,
        MetricAttachments,
    },
    props: {
        metricValue: {
            type: Object,
            required: true,
            default: () => structuredClone(newForm),
        },
        modal: {
            type: Boolean,
            required: false,
            default: false,
        },
        unitDetails: {
            type: String,
            required: true,
            default: "",
        },
    },
    data() {
        return {
            saving: {
                value: false,
                description: false,
            },
            savedStatus: {
                value: "",
                description: "",
            },
            // We need to save these because we can't call $t after unmounting in an async method
            message_created: this.$t("al_created_cmv"),
            message_updated: this.$t("al_updated_cmv"),
        };
    },
    computed: {
        ...mapState({
            sites: (state) => state.data.sites,
            businessUnits: (state) => state.data.businessUnits,
        }),
        valueState() {
            return !isNaN(+this.localData?.value);
        },
        descriptionState() {
            return (
                !this.localData?.description ||
                (this.localData?.description?.length > 1 &&
                    this.localData?.value?.length > 1)
            );
        },
        localData: {
            get() {
                return this.metricValue;
            },
            set(value) {
                this.$emit("input-update", value);
            },
        },
        periodLocked() {
            return this.$store.getters.getCurrentPeriod().locked === 1;
        },
    },

    methods: {
        getSite(id) {
            return this.sites.find((x) => x.id === id)?.name;
        },

        getBu(id) {
            return this.businessUnits.find((x) => x.id === id)?.name;
        },

        // Triggers on every input blur or form submit
        handleFormSubmit(updatedField) {
            // Set saving fields: full form submit updates all fields, otherwise only the updated field
            if (!updatedField) {
                Object.keys(this.saving).forEach((key) => {
                    this.saving[key] = true;
                });
            } else {
                this.saving[updatedField] = true;
            }

            // Save the data, if there is no ID, create a new one
            if (this.localData.id === null) {
                this.createMetricValue(updatedField);
            } else {
                this.updateMetricValue(updatedField);
            }
        },

        // API CALLS
        async createMetricValue() {
            try {
                const response = await customMetricsApi.createCustomMetricValue(
                    this.localData?.metric_id,
                    this.localData
                );

                this.handleSaveStatus();

                const result = response.data.data;
                if (result) {
                    this.localData.id = result.id;
                    this.$store.dispatch(types.SET_TOAST, this.message_created);
                }
                this.$emit("refresh");
            } catch (error) {
                console.log(error);
                this.handleSaveStatus(error);
            }
        },

        async updateMetricValue() {
            try {
                const response = await customMetricsApi.updateCustomMetricValue(
                    this.localData?.metric_id,
                    this.localData,
                    this.localData?.id
                );

                this.handleSaveStatus();

                const result = response.data.data;
                if (result) {
                    this.$store.dispatch(types.SET_TOAST, this.message_updated);
                }
                this.$emit("refresh");
            } catch (error) {
                console.log(error);
                this.handleSaveStatus(error);
            }
        },

        // SAVED INDICATOR HANDLER
        handleSaveStatus(error) {
            Object.keys(this.saving).forEach((key) => {
                this.saving[key] = false;
                this.savedStatus[key] = error ? "error" : "success";
            });
        },
    },
};
</script>
