<template>
    <div>
        <div
            v-if="
                !member.access_all_business_units &&
                businessAccessArray.length > 0
            "
        >
            <Badge class="badge--blue badge--noclick">
                {{ businessAccessArray[0]?.name || $t("al_unknown") }}
            </Badge>
            <p
                v-if="businessAccessArray.length > 1"
                class="text-gray-500 light text-small ml-1 mb-0"
            >
                {{ $t("and") }}
                {{ businessAccessArray.length - 1 }}
                {{ $t("more") }}
            </p>
        </div>

        <Badge class="badge--blue badge--noclick" v-else>
            {{ $t("all_business_units") }}
        </Badge>
    </div>
</template>

<script setup lang="ts">
import { type PropType, computed } from "vue";
import { useStore } from "vuex";
const store = useStore();
import Badge from "primevue/badge";

const props = defineProps({
    member: {
        type: Object as PropType<TTeamMember>,
        required: true,
    },
});

const businessAccessArray = computed(() => {
    const businesses = props.member.constraints?.business_unit_ids;

    if (!businesses) return [];
    const res = businesses.map((x) => {
        return store.getters.getBusinessUnit(x);
    });

    return res;
});
</script>
