<template>
    <div class="menu-select__item">
        <i class="fas fa-search mr-3"></i>
        <InputText
            @update:modelValue="searchQuery = $event"
            :modelValue="search"
            placeholder="Search..."
            size="small"
        />
    </div>
</template>

<script>
import * as types from "@/store/types";
import InputText from "primevue/inputtext";

import { mapState } from "vuex";

export default {
    components: {
        InputText,
    },
    props: {
        opened: {
            type: Boolean,
            required: true,
            default: false,
        },
    },
    data() {
        return {
            timeout: null,
            searchQuery: this.search || "",
        };
    },
    computed: {
        ...mapState({
            search: (state) => state.filters.search,
        }),
    },
    methods: {
        handleInput() {
            this.$store.dispatch(types.SET_FILTER, {
                search: this.searchQuery,
            });
        },
        handleInputDebounce() {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.handleInput();
            }, 300);
        },
    },
    watch: {
        searchQuery: {
            handler() {
                this.handleInputDebounce();
            },
        },
    },
};
</script>
