<template>
    <div class="cc-accordion">
        <div
            class="cc-accordion__header shadow medium flex justify-content-between align-items-center"
            @click.prevent="toggleAccordionVisibility"
        >
            <div class="flex align-items-center gap-2">
                <div class="cc-accordion__header-icon flex align-items-center">
                    <i
                        class="fas fa-chevron-right cc-accordion__header-icon-animation"
                        :class="
                            showAccordionItems
                                ? 'cc-accordion__header-icon-animation--active'
                                : null
                        "
                    ></i>
                </div>
                <span
                    class="cc-accordion__header-text flex flex-column align-items-start justify-content-center mr-4"
                >
                    <h3>{{ customMetric.name }}</h3>
                    <div
                        :id="customMetric.id + 'metric-description'"
                        v-if="customMetric.description"
                        class="light text-sm cc-table__column--ellipsis"
                        @mouseover="togglePopover"
                        @mouseleave="togglePopover"
                    >
                        {{ customMetric.description }}
                    </div>
                    <Popover
                        ref="popover"
                        v-if="customMetric.description?.length > 40"
                        :target="customMetric.id + 'metric-description'"
                        placement="right"
                        triggers="hover"
                    >
                        <span>{{ customMetric.description }}</span>
                    </Popover>
                </span>
                <Chip class="badge text-sm" variant="secondary">
                    {{ customMetric.unit }}
                </Chip>
                <Chip class="badge text-sm" variant="secondary">
                    {{ domain }}
                </Chip>
            </div>
            <!-- Accordion action buttons -->
            <div class="flex gap-2" v-if="period.locked !== 1">
                <Button
                    v-if="$can('update', 'metric')"
                    icon="fas fa-pencil"
                    rounded
                    outlined
                    @click.stop="$emit('select-metric', customMetric)"
                    v-tooltip.bottom="$t('edit')"
                ></Button>
                <Button
                    v-if="$can('delete', 'metric')"
                    icon="fas fa-trash"
                    rounded
                    outlined
                    @click.stop="$emit('delete-metric', customMetric)"
                    v-tooltip.bottom="$t('delete')"
                    severity="danger"
                ></Button>
            </div>
        </div>
        <!-- Accordion table  -->
        <transition name="slide-down">
            <div class="cc-accordion__list" v-if="showAccordionItems">
                <slot></slot>
            </div>
        </transition>
    </div>
</template>

<script>
import DomainMixin from "@/mixins/custom_metrics/DomainMixin";
import { mapState } from "vuex";
import Chip from "primevue/chip";
import Popover from "primevue/popover";
import Button from "primevue/button";

export default {
    name: "MetricAccordion",
    mixins: [DomainMixin],
    components: {
        Chip,
        Popover,
        Button,
    },
    props: {
        customMetric: {
            type: Object,
            required: true,
            description: "The custom metric data.",
        },
        collapseAll: {
            type: Boolean,
            required: true,
            default: false,
        },
        expandAll: {
            type: Boolean,
            required: true,
            default: false,
        },
    },
    data() {
        return {
            showAccordionItems: false,
            domain: null,
        };
    },
    computed: {
        ...mapState({ periodId: (state) => state.filters.period }),
        period() {
            return this.$store.getters.getCurrentPeriod();
        },
    },
    methods: {
        toggleAccordionVisibility() {
            this.showAccordionItems = !this.showAccordionItems;
        },
        togglePopover(event) {
            if (!this.$refs.popover) return;
            this.$refs.popover.toggle(event);
        },
    },

    watch: {
        customMetric: {
            handler(newVal) {
                if (newVal) {
                    this.selectedDomain(newVal);
                }
            },
            immediate: true,
        },
        collapseAll: {
            handler(newVal, oldVal) {
                if (newVal === oldVal) return;

                if (newVal === true) this.showAccordionItems = false;
            },
        },
        expandAll: {
            handler(newVal, oldVal) {
                if (newVal === oldVal) return;

                if (newVal === true) this.showAccordionItems = true;
            },
        },
        showAccordionItems: {
            handler(newVal) {
                if (newVal) {
                    this.$emit("accordion-opened", this.customMetric.id);
                } else {
                    this.$emit("accordion-closed", this.customMetric.id);
                }
            },
        },
    },
};
</script>
