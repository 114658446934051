<script>
import { mapState } from "vuex";
import insightsApi from "@/api/insights";

import * as types from "@/store/types";

export default {
    data() {
        return {
            total: 0,
        };
    },
    computed: {
        ...mapState({
            filters: (state) => state.filters,
        }),
    },
    methods: {
        async getTotal() {
            try {
                const result = await insightsApi.total();
                this.total = this.$checkDecimals(result.data.emissions) ?? 0;
            } catch (error) {
                console.log(error);
            }
        },

        // CATEGORIES
        async getCatData() {
            try {
                const result = await insightsApi.categories();
                this.catData = result.data;
            } catch (error) {
                console.log(error);
            }
        },
        async getCatEvolution() {
            try {
                const result = await insightsApi.evolution();
                this.catEvolution = result.data;
            } catch (error) {
                console.log(error);
            }
        },

        // SOURCES
        async getSourceData() {
            try {
                const result = await insightsApi.sources();
                this.sourceData = result.data;
            } catch (error) {
                console.log(error);
            }
        },
        async getSourceEvolution() {
            try {
                const result = await insightsApi.evolution();
                this.sourceEvolution = result.data;
            } catch (error) {
                console.log(error);
            }
        },

        // SITES
        async getSiteData() {
            try {
                const result = await insightsApi.sites();
                this.siteData = result.data;
            } catch (error) {
                console.log(error);
            }
        },
        async getSiteEvolution() {
            this.siteData = [];

            try {
                const result = await insightsApi.evolution();
                this.siteEvolution = result.data;
            } catch (error) {
                console.log(error);
            }
        },

        // BUSINESS UNITS
        async getBuData() {
            try {
                const result = await insightsApi.bus();
                this.buData = result.data;
            } catch (error) {
                console.log(error);
            }
        },
        async getBuEvolution() {
            try {
                const result = await insightsApi.evolution();
                this.buEvolution = result.data;
            } catch (error) {
                console.log(error);
            }
        },

        // UTILS
        handleChartClicked(data) {
            if (data.period_id)
                this.$store.dispatch(types.SET_FILTER, {
                    period: data.period_id,
                });
            if (data.business_unit_id && data.business_unit_id !== -1)
                this.$store.dispatch(types.SET_FILTER, {
                    bu: data.business_unit_id,
                });
            if (data.site_id && data.site_id !== -1)
                this.$store.dispatch(types.SET_FILTER, { site: data.site_id });
            if (data.cat_id && data.cat_id !== -1)
                this.$store.dispatch(types.SET_FILTER, {
                    category: data.cat_id,
                });
        },
    },
    watch: {
        filters: {
            handler() {
                this.init();
            },
            deep: true,
        },
    },
};
</script>
