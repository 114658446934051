<template>
    <Dialog
        :header="
            editedTag === null
                ? $t('tags_handleAddTag_title')
                : $t('tags_handleEdit_title')
        "
        :visible="showForm"
        @update:visible="emit('update:showForm', $event)"
        modal
    >
        <form @submit.prevent="handleSubmit" class="cc-form" id="tag-form">
            <div class="cc-form-group">
                <label for="name">{{ $t("label_name") }}</label>
                <CcInput id="name" v-model="tag.title" required></CcInput>
                <div class="cc-form__hint cc-form__hint--right">
                    {{ tag.title.length }} / 25
                </div>
            </div>
        </form>

        <template #footer>
            <div class="flex justify-content-end gap-3">
                <Button
                    :label="$t('cancel')"
                    @click="$emit('update:showForm', false)"
                    outlined
                />
                <Button :label="$t('save')" type="submit" form="tag-form" />
            </div>
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import { ref, type PropType, watch } from "vue";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import tagsApi from "@/api/tags";

const initialState = {
    title: "",
};

const tag = ref<TTag>({ ...initialState });

const props = defineProps({
    editedTag: {
        type: Object as PropType<TTag>,
        required: false,
        default: null,
    },
    showForm: {
        type: Boolean,
        required: true,
    },
});

const emit = defineEmits(["refresh", "close", "update:showForm"]);

const handleSubmit = async () => {
    if (props.editedTag) {
        await editTag();
    } else {
        await createTag();
    }

    emit("update:showForm", false);
    emit("refresh");
};

const createTag = async () => {
    try {
        await tagsApi.create({ title: tag.value.title });
    } catch (error) {
        console.log(error);
    }
};

const editTag = async () => {
    try {
        await tagsApi.edit({ title: tag.value.title }, props.editedTag.id);
    } catch (error) {
        console.log(error);
    }
};

watch(
    () => props.editedTag,
    (val) => {
        if (val) {
            tag.value = { ...val };
        } else {
            tag.value = { ...initialState };
        }
    },
    { immediate: true }
);
</script>
