<template>
    <div>
        <div v-if="member.access_all_sites === 0 && siteAccessArray.length > 0">
            <Badge
                class="badge--blue badge--noclick"
                :class="{
                    'badge--red': siteAccessArray[0].deleted,
                    'badge--blue': !siteAccessArray[0].deleted,
                }"
            >
                {{ siteAccessArray[0].name }}
            </Badge>
            <p
                v-if="siteAccessArray.length > 1"
                class="text-gray-500 light text-small ml-1 mb-0"
            >
                {{ siteAccessArray.length - 1 }}
            </p>
        </div>

        <Badge class="badge--blue badge--noclick" v-else>
            {{ $t("all_sites") }}
        </Badge>
    </div>
</template>

<script setup lang="ts">
import { type PropType, computed } from "vue";
import { useStore } from "vuex";
const store = useStore();
import Badge from "primevue/badge";

const props = defineProps({
    member: {
        type: Object as PropType<TTeamMember>,
        required: true,
    },
});

const siteAccessArray = computed(() => {
    const sites = props.member.constraints?.site_ids;

    if (!sites) return [];
    const res = sites.map((x) => {
        return (
            store.getters.getSite(x) ||
            store.getters.getDeletedSite(x) || {
                name: `Unknown (${x})`,
            }
        );
    });

    return res;
});
</script>
