<template>
    <div>
        <CcInput
            type="number"
            :model-value="localValueThreshold"
            @update:model-value="handleThresholdInput"
            min="1"
            step="1"
        />
        <Slider
            :model-value="localValueThreshold"
            @update:modelValue="handleThresholdInput"
            min="1"
            max="100"
            step="1"
        />
        <Transition mode="out-in" name="slide-down">
            <p class="text-xs text-danger" v-if="localValueThreshold < 1">
                {{ $t("input_value_threshold_error") }}
            </p>
        </Transition>
    </div>
</template>

<script>
import Slider from "primevue/slider";

export default {
    components: { Slider },
    props: {
        valueThresholdObject: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            localValueThreshold: 25,
            updateTimeout: null,
        };
    },
    methods: {
        handleThresholdInput(value) {
            this.localValueThreshold = +value;
            if (value < 1) return;
            clearTimeout(this.updateTimeout);
            this.updateTimeout = setTimeout(() => {
                this.$emit("update:valueTreshold", value);
            }, 300);
        },
    },
    watch: {
        valueThresholdObject: {
            handler(value) {
                if (!value) return;
                this.localValueThreshold = +value.configuration_value;
            },
            immediate: true,
        },
    },
};
</script>
