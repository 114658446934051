<template>
    <div>
        <label
            v-for="item in consolidationApproachOptions"
            :key="item.value"
            class="custom-metric__radio-select"
            :class="
                consolidationApproach === item.value
                    ? 'custom-metric__radio-select--selected'
                    : ''
            "
            :for="item.value"
        >
            <div class="flex flex-column">
                <div class="text-gray-600">
                    {{ item.text }}
                </div>
                <p>
                    {{ item.description }}
                </p>
            </div>
            <RadioButton
                :model-value="consolidationApproach"
                :id="item.value"
                name="consolidation-radios"
                :value="item.value"
                @update:model-value="handleConsolidationApproachChange"
                class="ml-2"
            >
            </RadioButton>
        </label>
    </div>
</template>

<script>
import RadioButton from "primevue/radiobutton";

export default {
    components: { RadioButton },
    props: {
        consolidationApproachObject: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            consolidationApproach: "operational_control",
            consolidationApproachOptions: [
                {
                    text: this.$t("equity_share"),
                    value: "equity_share",
                    description: this.$t("equity_share_description"),
                },
                {
                    text: this.$t("financial_control"),
                    value: "financial_control",
                    description: this.$t("financial_control_description"),
                },
                {
                    text: this.$t("operational_control"),
                    value: "operational_control",
                    description: this.$t("operational_control_description"),
                },
            ],
        };
    },
    methods: {
        getDescription(value) {
            return this.consolidationApproachOptions.find(
                (option) => option.value === value
            ).description;
        },
        handleConsolidationApproachChange(value) {
            this.consolidationApproach = value;
            this.$emit("update:consolidationApproach", value);
        },
    },
    watch: {
        consolidationApproachObject: {
            handler(value) {
                if (!value) return;
                this.consolidationApproach = value.configuration_value;
            },
            immediate: true,
        },
    },
};
</script>
