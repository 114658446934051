<template>
    <Card>
        <template #title>{{ $t("dashboard_activity") }}</template>
        <template #content>
            <ActivityList
                :activityData="activityData"
                v-if="activityData.length > 0"
                expanded
                class="mt-4"
            />
            <p v-else class="text-gray-500 text-tiny">
                {{ $t("dashboard_no_activity") }}
            </p>
        </template>
    </Card>
</template>

<script>
import ActivityList from "@/components/elements/ActivityList.vue";
import activityApi from "@/api/emission_activity.js";
import Card from "primevue/card";

export default {
    components: {
        ActivityList,
        Card,
    },
    data() {
        return {
            activityData: [],
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.getActivity();
        },
        async getActivity() {
            try {
                const result = await activityApi.getAllActivity();

                this.activityData = result.data;
            } catch (error) {
                console.log(error);
            }
        },
    },
};
</script>
