import http from "../http-common";

const api = {
    getUser: () => {
        return http.get("/user/details");
    },

    updateUser: (user) => {
        return http.post("/user/update", user);
    },

    getAvailableCompanies: () => {
        return http.get("/user/companies");
    },
};

export default api;
