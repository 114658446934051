<template>
    <transition name="fade" mode="out-in">
        <div
            class="loader__wrapper loader__wrapper--global"
            @click.stop
            v-if="showLoader"
        >
            <img
                :src="require('../../assets/logo.svg')"
                class="loader__icon"
                alt="Futureproofed"
            />
            <p class="text-gray-600">{{ $t("loading_app") }}</p>
        </div>
    </transition>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "CCLoader",
    computed: {
        ...mapState({
            showLoader: (state) => state.showLoader,
        }),
    },
};
</script>
