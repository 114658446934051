<template>
    <div class="cc-saving-status-loader">
        <ProgressSpinner v-if="busySaving" style="width: 20px; height: 20px" />
        <div v-show="!busySaving">
            <p v-if="status == 'success'" class="color-success">
                <i class="fas fa-check-circle"></i>
            </p>
            <p v-if="status == 'error'" class="color-error">
                <i class="fas fa-exclamation-circle"></i>
            </p>
            <p v-if="status == 'waiting'" class="text-light">
                <i class="fas fa-check-circle"></i>
            </p>
            <p v-if="status == ''" class="text-light" style="opacity: 0">
                <i class="fas fa-check-circle"></i>
            </p>
        </div>
    </div>
</template>

<script>
import ProgressSpinner from "primevue/progressspinner";

export default {
    components: {
        ProgressSpinner,
    },
    props: {
        saving: {
            type: Boolean,
            required: true,
        },
        savedStatus: {
            type: String,
            required: false,
            default: "",
            description:
                "Current status of the saving http request, can be success, error or waiting.",
        },
    },
    data: () => ({
        status: "",
        busySaving: false,
        statusTimer: null,
    }),
    watch: {
        saving() {
            if (this.savingTimer) {
                clearTimeout(this.statusTimer);
            }

            if (this.saving == true) {
                this.busySaving = this.saving;
                this.status = "";
                return;
            }

            if (this.saving == false) {
                // A little delay so that the user can see the loading happening!
                setTimeout(() => (this.busySaving = this.saving), 300);
                this.statusTimer = setTimeout(
                    () => (this.status = this.savedStatus),
                    300
                );
            }

            if (this.saving == false && this.savedStatus == "success") {
                this.statusTimer = setTimeout(() => {
                    this.status = "waiting";
                }, 2500);
            }
        },
    },
    mounted() {
        this.status = this.savedStatus;
    },
    methods: {},
};
</script>
