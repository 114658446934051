// Set your APP_ID
const APP_ID = "eakdf2wf";

window.intercomSettings = {
    app_id: APP_ID,
};

// Loads Intercom with the snippet
// This must be run before boot, it initializes window.Intercom
exports.load = () => {
    (function () {
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === "function") {
            ic("reattach_activator");
            ic("update", w.intercomSettings);
        } else {
            var d = document;
            var i = function () {
                i.c(arguments);
            };
            i.q = [];
            i.c = function (args) {
                i.q.push(args);
            };
            w.Intercom = i;
            var l = function () {
                var s = d.createElement("script");
                s.type = "text/javascript";
                s.async = true;
                s.src = "https://widget.intercom.io/widget/" + APP_ID;
                var x = d.getElementsByTagName("script")[0];
                x.parentNode.insertBefore(s, x);
            };
            if (document.readyState === "complete") {
                l();
            } else if (w.attachEvent) {
                w.attachEvent("onload", l);
            } else {
                w.addEventListener("load", l, false);
            }
        }
    })();
};

// Initializes Intercom
// We add the User in the options but this might need a mapper to work correctly. See https://developers.intercom.com/installing-intercom/docs/javascript-api-attributes-objects
// I prepared a small mapper here, but if we need additional information, we can add it here as well.
exports.boot = (options = {}) => {
    const mappedOptions = {
        ...options,
        user_id: options.user_id,
        company_id: options.company_id,
        name: `${options.first_name} ${options.last_name}`,
    };
    window &&
        window.Intercom &&
        window.Intercom("boot", { app_id: APP_ID, ...mappedOptions });
};

exports.update = () => {
    window && window.Intercom && window.Intercom("update");
};

exports.show = () => {
    window.Intercom("show");
};
