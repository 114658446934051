<template>
    <Chip
        class="badge badge-pill"
        :class="metricValue.site_id === null ? 'badge--empty' : 'badge--blue'"
    >
        <span>{{ getSitesBadgeText(metricValue) }} </span>
    </Chip>
</template>

<script>
import Chip from "primevue/chip";

export default {
    components: {
        Chip,
    },
    props: {
        metricValue: {
            type: Object,
            required: true,
        },
        sites: {
            type: Array,
            required: true,
        },
    },
    methods: {
        getSiteName(id) {
            if (!id) return "";
            const site = this.sites.find((x) => x.id === id);

            if (site) return site.name;
            else return this.$t("badge_no_site");
        },
        getSitesBadgeText(metricValue) {
            const siteId = metricValue.site_id;
            const businessUnitId = metricValue.business_unit_id;

            if (!siteId && !businessUnitId) return this.$t("all_sites");
            if (!siteId && businessUnitId) return this.$t("badge_no_site");

            return this.getSiteName(siteId) ?? this.$t("all_sites");
        },
    },
};
</script>
