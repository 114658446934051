import http from "../http-common";

const api = {
    getDetails: () => {
        return http.get("/company/details");
    },

    updateDetails: (params) => {
        return http.post("/company/update", params);
    },

    uploadLogo: (params) => {
        return http.post("/company/upload", params, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    },

    removePhoto: () => {
        return http.post("/company/removephoto");
    },

    // sites and site groups

    getStructure: () => {
        return http.get("/company/structure");
    },

    // Team
    getTeam: () => {
        return http.get("/company/team");
    },

    deleteUser: (user) => {
        return http.post("/company/deleteuser", user);
    },

    updateUserRestrictions: (user) => {
        return http.post("/company/updateUserRestrictions", user);
    },

    updateAllSiteAccess: (user) => {
        return http.post("/company/updateAllSiteAccess", user);
    },

    updateSiteConstraints: (user) => {
        return http.post("/company/updateSiteConstraints", user);
    },
};

export default api;
