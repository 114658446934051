<template>
    <div class="custom-metric-sidebar__header">
        <div>
            <h2 class="mb-0">{{ title }}</h2>
        </div>
        <div class="mr-2">
            <Button
                id="maximize_button"
                text
                icon="fas fa-up-right-and-down-left-from-center"
                @click="$emit('open-modal')"
                v-tooltip.bottom="$t('maximize')"
            >
            </Button>
        </div>
    </div>
</template>

<script>
import Button from "primevue/button";

export default {
    name: "MetricSidebarHeader",
    components: {
        Button,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
    },
};
</script>
