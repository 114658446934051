<template>
    <div>
        <span
            @mouseover="togglePopover"
            @mouseleave="togglePopover"
            :id="`${metricValue.id}-metric-description`"
        >
            {{ metricValue.description }}
        </span>
        <Popover
            ref="detailsPopover"
            v-if="metricValue.description?.length > 40"
            :target="`${metricValue.id}-metric-description`"
            placement="right"
            triggers="hover"
        >
            <span>{{ metricValue.description }}</span>
        </Popover>
    </div>
</template>

<script>
import Popover from "primevue/popover";

export default {
    components: {
        Popover,
    },
    props: {
        metricValue: {
            type: Object,
            required: true,
        },
    },
    methods: {
        togglePopover() {
            if (!this.$refs.detailsPopover) return;
            this.$refs.detailsPopover.toggle();
        },
    },
};
</script>
