<template>
    <Card>
        <template #title>
            <h2>{{ $t("emissionCategory_header") }}</h2>
        </template>

        <template #content>
            <div class="categories__grid">
                <UpstreamSelection
                    :checked-categories="checkedCategories"
                    @update:category="update"
                    :scope2="filterCategories('upstream', 2)"
                    :scope3="filterCategories('upstream', 3)"
                />
                <DirectSelection
                    :checked-categories="checkedCategories"
                    @update:category="update"
                    :direct="filterCategories('direct')"
                />
                <DownstreamSelection
                    :checked-categories="checkedCategories"
                    @update:category="update"
                    :downstream="filterCategories('downstream')"
                />
            </div>
            <div style="margin-top: 40px">
                <img src="/GHGProtocol.png" class="w-full" />
            </div>
        </template>
    </Card>
</template>

<script>
import categoryApi from "@/api/emission_categories";
import configApi from "@/api/emission_category_config.js";
import Card from "primevue/card";
import UpstreamSelection from "./category-selection/UpstreamSelection.vue";
import DirectSelection from "./category-selection/DirectSelection.vue";
import DownstreamSelection from "./category-selection/DownstreamSelection.vue";

export default {
    components: {
        Card,
        UpstreamSelection,
        DirectSelection,
        DownstreamSelection,
    },
    data() {
        return {
            categories: [],
            checkedCategories: [],
            config: {},
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            await this.getCategories();
            await this.getCurrentConfig();
        },
        async getCategories() {
            try {
                const result = await categoryApi.listAllCategories();
                this.categories = result.data;
            } catch (error) {
                console.log(error);
            }
        },
        async getCurrentConfig() {
            try {
                const res = await configApi.get();
                this.checkedCategories = res.data;
            } catch (error) {
                console.log(error);
            }
        },
        async setCurrentConfig() {
            try {
                await configApi.update(this.checkedCategories);
            } catch (error) {
                console.log(error);
            }
        },
        filterCategories(valuechain, scope) {
            const valueChainFilter = this.categories.filter(
                (x) => x.value_chain === valuechain
            );
            if (scope)
                return valueChainFilter.filter((x) => +x.scope === scope);
            return valueChainFilter;
        },
        update(id) {
            const index = this.checkedCategories.findIndex((x) => x === id);

            if (index === -1) this.checkedCategories.push(id);
            else this.checkedCategories.splice(index, 1);

            this.setCurrentConfig();
        },
    },
};
</script>
