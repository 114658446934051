const sortSourcesUtil = (sources, sort, sites, businessUnits) => {
    switch (sort) {
        case "date":
            return sources.sort((a, b) => {
                const dateA = new Date(
                    a.emission_data_updated_at || a.updated_at
                ).getTime();
                const dateB = new Date(b.emission_data_updated_at).getTime();
                return dateB - dateA;
            });
        case "nameAZ":
            return sources.sort((a, b) => {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
            });
        case "nameZA":
            return sources.sort((a, b) => {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();
                if (nameA < nameB) return 1;
                if (nameA > nameB) return -1;
                return 0;
            });
        case "descriptionAZ":
            return sources.sort((a, b) => {
                const descriptionA = a.description?.toLowerCase();
                const descriptionB = b.description?.toLowerCase();
                if (descriptionA < descriptionB) return -1;
                if (descriptionA > descriptionB) return 1;
                return 0;
            });
        case "descriptionZA":
            return sources.sort((a, b) => {
                const descriptionA = a.description?.toLowerCase();
                const descriptionB = b.description?.toLowerCase();
                if (descriptionA < descriptionB) return 1;
                if (descriptionA > descriptionB) return -1;
                return 0;
            });
        case "emissions09":
            return sources.sort((a, b) => {
                const emA = a.calculated_emissions;
                const emB = b.calculated_emissions;
                if (emA > emB) return -1;
                if (emA < emB) return 1;
                return 0;
            });
        case "emissions90":
            return sources.sort((a, b) => {
                const emA = a.calculated_emissions;
                const emB = b.calculated_emissions;
                if (emA > emB) return 1;
                if (emA < emB) return -1;
                return 0;
            });
        case "bu":
            return sources.sort((a, b) => {
                const buA =
                    businessUnits.find((x) => x.id === a.business_unit_id)
                        ?.name || "zzzz";
                const buB =
                    businessUnits.find((x) => x.id === b.business_unit_id)
                        ?.name || "zzzz";
                if (buA > buB) return 1;
                if (buA < buB) return -1;
                return 0;
            });
        case "site":
            return sources.sort((a, b) => {
                const siteA =
                    sites.find((x) => x.id === a.site_id)?.name || "zzzz";
                const siteB =
                    sites.find((x) => x.id === b.site_id)?.name || "zzzz";
                return siteA.localeCompare(siteB);
            });
        case "value":
            return sources.sort((a, b) => {
                const valA =
                    typeof a.value === "string"
                        ? parseFloat(a.value) || 0
                        : a.value || 0;
                const valB =
                    typeof b.value === "string"
                        ? parseFloat(b.value) || 0
                        : b.value || 0;
                if (valA > valB) return -1;
                if (valA < valB) return 1;
                return 0;
            });
        case "type":
            return sources.sort((a, b) => {
                const typeA = a.emission_type?.type?.toLowerCase() || "zzzz";
                const typeB = b.emission_type?.type?.toLowerCase() || "zzzz";
                if (typeA > typeB) return 1;
                if (typeA < typeB) return -1;
                return 0;
            });
    }
};

export default sortSourcesUtil;
