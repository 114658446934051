<template>
    <div>
        <p class="m-0 text-tiny text-gray-500">
            <span class="text-gray-600"> {{ $t("form_admin") }} </span>:
            {{ $t("form_admin_text") }}
        </p>
        <p class="m-0 text-tiny text-gray-500">
            <span class="text-gray-600"> {{ $t("form_editor") }} </span>:
            {{ $t("form_editor_text") }}
        </p>
        <p class="m-0 text-tiny text-gray-500">
            <span class="text-gray-600"> {{ $t("form_contributor") }} </span>:
            {{ $t("form_contributor_text") }}
        </p>
        <p class="m-0 text-tiny text-gray-500">
            <span class="text-gray-600"> {{ $t("form_auditor") }} </span>:
            {{ $t("form_auditor_text") }}
        </p>
        <p class="m-0 text-tiny text-gray-500">
            <span class="text-gray-600"> {{ $t("form_read_only") }} </span>:
            {{ $t("form_read_only_text") }}
        </p>
    </div>
</template>

<script setup lang="ts"></script>
