<template>
    <div>
        <cc-header class="border-bottom" :title="$t('cm_header')"></cc-header>

        <div class="content-container section">
            <metric-list />
        </div>
    </div>
</template>
<script>
import MetricList from "@/components/custom_metrics/MetricList.vue";
import CcHeader from "@/components/header/CcHeader.vue";

export default {
    name: "CompanyMetrics",
    components: {
        CcHeader,
        MetricList,
    },
};
</script>
