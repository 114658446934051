<template>
    <div>
        <div d3-chart :id="chartKey" v-show="!hideGraph"></div>
        <div v-if="hideGraph" class="ml-2 text-gray-500 text-tiny">
            {{ $t("charts_no_data") }}
        </div>
    </div>
</template>

<script>
import * as d3 from "d3";

export default {
    props: {
        data: {
            type: Array,
            required: true,
        },
        labelKey: {
            type: String,
            required: true,
            description:
                "The key in the data objects that contain the desired label on the chart",
        },
        chartKey: {
            type: String,
            required: false,
            default: "d3-chart",
            description:
                "If we have multiple charts on one page, we need unique keys. Not necessary when a single chart is shown.",
        },
        barStyle: {
            type: String,
            required: false,
            default: "bar__blue",
            description: "Add a custom CSS class to each of the bars drawn",
        },
        customStyle: {
            type: Object,
            required: false,
            default: null,
            description:
                'Object that contains custom style for each seperate bar. Object contains the key for the bar and an object "style" where we can match the key to the required style',
        },
        barHeight: {
            type: Number,
            required: false,
            default: 80,
        },
        barPadding: {
            type: Number,
            required: false,
            default: 0.85,
        },
        idKey: {
            type: String,
            required: true,
            description: "The key in the data objects that contain the id",
        },
        width: {
            type: Number,
            required: false,
            default: 1000,
        },
    },
    data() {
        return {
            svg: null,
            xScale: null,
            yScale: null,
            graph: null,
        };
    },
    computed: {
        highestDataPoint() {
            const emissions = this.data.map((x) => x.emissions);
            const highest = Math.max(...emissions);
            return highest + (highest / 100) * 12;
        },
        chartHeight() {
            return this.data.length * this.barHeight;
        },
        hideGraph() {
            // Graph should be hidden if there is 0 emissions to be shown.
            let emissions = 0;
            this.data.forEach((item) => {
                emissions += item.emissions;
            });

            return emissions === 0;
        },
    },
    mounted() {
        this.generateChart();
    },
    methods: {
        generateChart() {
            // Create the SVG element
            this.svg = d3
                .select("#" + this.chartKey)
                .append("svg")
                .attr("viewBox", `0 0 ${this.width} ${this.chartHeight}`);

            // Create the functions for the X and Y scale
            this.xScale = d3
                .scaleLinear()
                .rangeRound([0, 800])
                .domain([0, this.highestDataPoint]);
            this.yScale = d3
                .scaleBand()
                .range([0, this.chartHeight])
                .padding(this.barPadding)
                .domain(this.data.map((x) => x[this.idKey]));

            // Add the scales to the SVG first, to make sure the bars overlay the scale
            this.svg
                .append("g")
                .attr("transform", `translate(200, ${this.chartHeight - 20})`)
                .call(
                    d3
                        .axisBottom(this.xScale)
                        .tickSize(-this.chartHeight)
                        .tickFormat(d3.format("~s"))
                );

            this.svg
                .append("g")
                .attr("transform", "translate(200, 0)")
                .attr("class", "vertical-ticks__text")
                .call(
                    d3
                        .axisLeft(this.yScale)
                        .tickSize(0)
                        .tickFormat((i, d) =>
                            this.$t(this.data[d][this.labelKey])
                        )
                );

            // Add, size and position the bars to the chart, also add mouse events
            this.graph = this.svg
                .selectAll(".bar")
                .data(this.data)
                .enter()
                .append("rect")
                .classed(`bar ${this.barStyle} bar__horizontal`, true)
                .attr("style", (data) => {
                    if (!this.customStyle) return "";
                    const value = data[this.customStyle.key];
                    if (!value) return "";
                    if (!this.customStyle.styles[value]) return "";

                    return this.customStyle.styles[value];
                })
                .attr("height", this.yScale.bandwidth())
                .attr("width", (data) => this.xScale(data.emissions))
                .attr("rx", 1.5)
                .attr("y", (data) => this.yScale(data[this.idKey]))
                .on("mouseout", (e) => this.addHoverData(e, null))
                .on("mousemove", (e, data) => this.addHoverData(e, data))
                .on("mouseup", (e, data) => this.$emit("bar-clicked", data));

            // Style the scales
            this.svg.selectAll("g .domain").attr("stroke", "transparent");

            this.svg.selectAll("g .tick").attr("stroke-dasharray", "3,3");

            this.svg
                .selectAll(".vertical-ticks__text .tick text")
                .attr("textLength", (d, i, j) => this.checkLabelWidth(j[i]))
                .attr("lengthAdjust", "spacing");

            this.svg.selectAll("g .tick line").attr("stroke", "#adb5bd");
        },
        addHoverData(e, data) {
            // Check mouse coords
            const coords = d3.pointer(e);

            // Remove any previous hover boxes
            this.svg.selectAll(".hover-data, .hover-data__background").remove();

            // If the function is called with data, add a new hover box
            if (data !== null) {
                // Create and position the rect for the box
                const hoverbox = this.svg
                    .append("rect")
                    .attr("fill", "white")
                    .attr("height", 30)
                    .attr("stroke", "black")
                    .attr("rx", 5)
                    .classed("hover-data__background", true);
                // Create and position the text in the box
                const hovertext = this.svg
                    .append("text")
                    .attr(
                        "transform",
                        `translate(${190 + coords[0]}, ${coords[1] - 11})`
                    )
                    .attr(
                        "text-anchor",
                        coords[0] < this.width / 2 ? "start" : "end"
                    )
                    .classed("hover-data", true)
                    .text(
                        `${this.$checkDecimals(data.emissions)} ${data.unit}`
                    );
                // Get the width of the created text
                const hovertextWidth = hovertext._groups[0][0].getBBox().width;
                // Adjust the width of the hoverbox to the width of the text
                hoverbox
                    .attr("width", hovertextWidth + 20)
                    .attr(
                        "transform",
                        "translate(" +
                            (coords[0] < this.width / 2
                                ? 180 + coords[0]
                                : 180 + coords[0] - hovertextWidth) +
                            "," +
                            (coords[1] - 31) +
                            ")"
                    );
            }
        },
        checkLabelWidth(label) {
            if (!label) return "";
            const width = label.getBBox().width;

            if (width < 190) return width;
            return 190;
        },
    },
};
</script>
