import http from "../http-common";
import { createQueryString } from "./api-utils";

const api = {
    total: () => {
        const query = createQueryString({}, true);
        return http.get(`/insights/total?${query}`);
    },

    evolution: () => {
        // Period should be null to get all data
        const query = createQueryString({ period: null }, true);
        return http.get(`/insights/evolution?${query}`);
    },

    sites: () => {
        const query = createQueryString({}, true);
        return http.get(`/insights/sites?${query}`);
    },

    sources: () => {
        const query = createQueryString({}, true);
        return http.get(`/insights/sources?${query}`);
    },

    categories: () => {
        const query = createQueryString({}, true);
        return http.get(`/insights/categories?${query}`);
    },

    subcategories: () => {
        const query = createQueryString({}, true);
        return http.get(`/insights/subcategories?${query}`);
    },

    bus: () => {
        const query = createQueryString({}, true);
        return http.get(`/insights/bus?${query}`);
    },

    scopes: () => {
        const query = createQueryString({}, true);
        return http.get(`/insights/scopes?${query}`);
    },

    yearlyEmissionsPerScope: () => {
        return http.get(`/insights/yearly-emissions?granularity=scope`);
    },

    reductionTargets: () => {
        return http.get("/insights/reduction-targets");
    },

    validation: (periodId, addFilters) => {
        const query = createQueryString({ period: periodId }, addFilters);
        return http.get(`/insights/validation?${query}`);
    },
};

export default api;
