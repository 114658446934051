import http from "../http-common";

const api = {
    // INDIVIDUAL EMISSION SOURCES
    list: (emissionSourceId) => {
        return http.get(`/emission-source/${emissionSourceId}/tags`);
    },

    add: (emissionSourceId, tagId) => {
        return http.post(`/emission-source/${emissionSourceId}/tag/add`, tagId);
    },

    delete: (emissionSourceId, tagId) => {
        return http.post(
            `/emission-source/${emissionSourceId}/tag/delete`,
            tagId
        );
    },

    // MANAGEMENT
    suggest: (title) => {
        return http.get(`/tag/suggest?title=${title}`);
    },

    create: (body) => {
        return http.post("/tag/add", body);
    },

    listAll: () => {
        return http.get("/tags");
    },

    edit: (body, tagId) => {
        return http.post(`/tag/${tagId}/edit`, body);
    },

    deleteAdmin: (tagId) => {
        return http.post(`/tag/${tagId}/delete`);
    },
};

export default api;
