<template>
    <transition-group name="badge-animation" tag="div">
        <Chip
            v-for="bu in businessUnits"
            :key="bu.id"
            class="badge badge--blue mr-2 badge--close badge-animation-item"
            :style="getBadgeStyle(bu)"
            @click="deleteFilter(bu.id)"
        >
            {{ bu.name }}
        </Chip>
    </transition-group>
</template>

<script>
import * as types from "@/store/types";
import Chip from "primevue/chip";

export default {
    components: {
        Chip,
    },
    props: {
        filters: {
            type: Object,
            required: true,
        },
    },
    computed: {
        businessUnits() {
            const bus = [];

            this.filters.businessUnits.forEach((bu) => {
                if (bu === -1) {
                    bus.push({
                        id: -1,
                        name: this.$t("unassigned_business_units"),
                    });
                    return;
                }
                const currentBu = this.$store.getters.findDataPoint(
                    "businessUnits",
                    bu
                );

                bus.push(currentBu);
            });

            return bus;
        },
    },
    methods: {
        getBadgeStyle(bu) {
            if (!bu) return "";
            const colour = bu.colour;
            if (colour) {
                return `color: ${colour}; background-color: ${colour}10; box-shadow: none;`;
            }

            return "";
        },
        deleteFilter(id) {
            // Find the BU index
            const buIndex = this.filters.businessUnits.findIndex(
                (x) => x === id
            );

            // Remove the businessUnit from the selected array
            const selectedBus = [
                ...this.filters.businessUnits.slice(0, buIndex),
                ...this.filters.businessUnits.slice(buIndex + 1),
            ];
            this.$store.dispatch(types.SET_FILTER, {
                businessUnits: selectedBus,
            });
        },
    },
};
</script>
