<template>
    <Dialog
        :visible="showModal"
        @update:visible="$emit('update:showModal', $event)"
        modal
    >
        <template #header>
            <h2>{{ $t("team_invite_form_title") }}</h2>
        </template>

        <form @submit.prevent="save" id="invite-form" class="cc-form">
            <div class="cc-form-group">
                <label>{{ $t("email") }}</label>
                <CcInput
                    v-model="invite.email"
                    type="email"
                    required
                    :readonly="!!editInvite.id"
                />
            </div>
            <div class="cc-form-group">
                <label>{{ $t("first_name") }}</label>
                <CcInput
                    v-model="invite.first_name"
                    required
                    :readonly="!!editInvite.id"
                />
            </div>
            <div class="cc-form-group">
                <label>{{ $t("last_name") }}</label>
                <CcInput
                    v-model="invite.last_name"
                    required
                    :readonly="!!editInvite.id"
                />
            </div>

            <div class="cc-form-group">
                <label>{{ $t("team_invite_form_role") }}</label>
                <CcSelect v-model="invite.role" :options="roles" required />
                <RoleDefinitions />
            </div>

            <sites-multiselect
                :userSelected="invite.role_info.sites"
                :userAll="invite.role_info.access_all_sites"
                @update="handleSitesUpdate"
            />

            <business-units-multiselect
                :userSelected="invite.role_info.business_units"
                :userAll="invite.role_info.access_all_business_units"
                @update="handleBusinessUnitsUpdate"
            />
            <tags-multiselect
                :userSelected="invite.role_info.tag_ids"
                :userAll="invite.role_info.access_all_tags"
                @update="handleTagsUpdate"
            />

            <div class="cc-form-group">
                <label>{{ $t("team_invite_form_custom_message") }}</label>
                <Textarea
                    v-model="custom_message"
                    :readonly="!!editInvite.id"
                />
                <div class="cc-form__hint">
                    {{ $t("team_invite_form_custom_message_text") }}
                </div>
            </div>

            <p v-if="error" class="mt-2 text-mini color-error">
                {{ error }}
            </p>
        </form>

        <template #footer>
            <div class="flex gap-2">
                <Button
                    outlined
                    @click="$emit('update:showModal', false)"
                    :label="$t('cancel')"
                ></Button>
                <Button
                    type="submit"
                    form="invite-form"
                    :label="$t('save')"
                ></Button>
            </div>
        </template>
    </Dialog>
</template>

<script>
import inviteApi from "@/api/invite";
import BusinessUnitsMultiselect from "./components/BusinessUnitsMultiselect.vue";
import SitesMultiselect from "./components/SitesMultiselect.vue";
import TagsMultiselect from "./components/TagsMultiselect.vue";
import RoleDefinitions from "./components/RoleDefinitions.vue";
import Dialog from "primevue/dialog";
import Textarea from "primevue/textarea";
import Button from "primevue/button";

export default {
    components: {
        SitesMultiselect,
        BusinessUnitsMultiselect,
        TagsMultiselect,
        Dialog,
        RoleDefinitions,
        Textarea,
        Button,
    },
    props: {
        editInvite: {
            type: Object,
            required: false,
            default: () => {},
        },
        showModal: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            sites: this.$store.getters.getSites(),
            modal: this.showModal,
            invite: { role_info: { access_all_sites: true } },
            custom_message: "",
            showCompanySearch: false,
            roles: [
                { value: "admin", text: this.$t("form_admin") },
                { value: "editor", text: this.$t("form_editor") },
                { value: "contributor", text: this.$t("form_contributor") },
                { value: "auditor", text: this.$t("form_auditor") },
                { value: "read-only", text: this.$t("form_read_only") },
            ],
            error: "",
        };
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            this.error = "";
            this.invite = {
                role: "contributor",
                ...{
                    role_info: {
                        access_all_sites: true,
                        sites: [],
                        access_all_business_units: true,
                        business_units: [],
                        access_all_tags: true,
                        tag_ids: [],
                    },
                },
                ...this.editInvite,
            };
        },
        handleSitesUpdate({ selected, all }) {
            this.updateRoleInfo("sites", "access_all_sites", selected, all);
        },
        handleBusinessUnitsUpdate({ selected, all }) {
            this.updateRoleInfo(
                "business_units",
                "access_all_business_units",
                selected,
                all
            );
        },
        handleTagsUpdate({ selected, all }) {
            this.updateRoleInfo("tag_ids", "access_all_tags", selected, all);
        },
        updateRoleInfo(selectedKey, allKey, selected, all) {
            this.invite.role_info[selectedKey] = selected;
            this.invite.role_info[allKey] = all;
        },

        async save() {
            this.error = "";

            if (this.invite.role_info.access_all_sites)
                this.invite.role_info.sites = [];

            if (this.invite.role_info.access_all_business_units)
                this.invite.role_info.business_units = [];

            if (this.invite.role_info.access_all_tags)
                this.invite.role_info.tag_ids = [];

            if (this.editInvite?.invite_id) {
                try {
                    await inviteApi.updateInvite(
                        this.editInvite.invite_id,
                        this.invite
                    );
                    this.$emit("saved");
                } catch (error) {
                    console.log(error);
                    this.error = error.response.data;
                }
            } else {
                try {
                    await inviteApi.createInvite(
                        this.invite,
                        this.custom_message
                    );
                    this.$emit("saved");
                } catch (error) {
                    console.log(error);
                    this.error = error.response.data;
                }
            }
        },
    },
    watch: {
        showModal: {
            handler(val) {
                this.modal = val;

                if (val) this.init();
            },
        },
        modal: {
            handler(val) {
                if (val === false) this.$emit("close");
            },
        },
    },
};
</script>
