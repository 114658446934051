<template>
    <div>
        <cc-input
            v-model="inputValue"
            type="tel"
            placeholder="+0123456789"
            :required="required"
        ></cc-input>
        <div v-if="!isValid" class="text-tiny color-error">
            {{ invalidPhoneMessage }}
        </div>
    </div>
</template>

<script>
export default {
    name: "CCInputTel",
    props: {
        value: {
            type: [String, Number],
            required: false,
        },
        required: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            invalidPhoneMessage: "",
            isValid: false,
        };
    },
    computed: {
        inputValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.validatePhoneInput(value);
                this.$emit("input", value);
            },
        },
    },
    methods: {
        validatePhoneInput(value) {
            const validPhoneRegex = /^\+?[0-9]*$/;
            this.isValid = true;
            this.invalidPhoneMessage = "";

            if (!validPhoneRegex.test(value)) {
                this.invalidPhoneMessage = "Please enter a valid phone number.";
                this.isValid = false;
            } else if (value.length < 9) {
                this.invalidPhoneMessage =
                    "Phone number must be at least 9 characters.";
                this.isValid = false;
            }

            this.$emit("validation", this.isValid);
            return this.isValid;
        },
    },
};
</script>
