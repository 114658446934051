import http from "../http-common";

const api = {
    get: () => {
        return http.get("/emission-category-config/get");
    },

    update: (selectedIds) => {
        return http.post("/emission-category-config/update", selectedIds);
    },
};

export default api;
