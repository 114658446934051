<template>
    <Toast>
        {{ toast }}
    </Toast>
</template>

<script>
import { mapState } from "vuex";
import * as types from "@/store/types";
import Toast from "primevue/toast";

export default {
    name: "CCToast",
    components: {
        Toast,
    },
    computed: {
        ...mapState({
            toast: (state) => state.toast,
        }),
    },
    methods: {
        showToast() {
            if (this.toast === "") return;

            this.$toast.add({
                severity: "info",
                summary: this.toast,
                life: 3000,
            });

            setTimeout(() => {
                this.$store.dispatch(types.SET_TOAST, "");
            }, 3050);
        },
    },
    watch: {
        toast: {
            handler() {
                this.showToast();
            },
        },
    },
};
</script>
