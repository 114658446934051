<template>
    <Dialog
        :title="`${user.first_name} ${user.last_name} - ${user.email}`"
        :visible="showModal"
        @update:visible="$emit('update:showModal', $event)"
        modal
        style="max-width: 50vw"
    >
        <div v-if="user">
            <form @submit.prevent="handleSave" class="cc-form" id="user-form">
                <sites-multiselect
                    :userSelected="user?.constraints?.site_ids || []"
                    :userAll="!!user.access_all_sites"
                    @update="
                        ({ selected, all }) => {
                            this.selectSites = selected;
                            this.allSites = all;
                        }
                    "
                />
                <Divider />
                <business-units-multiselect
                    :userSelected="user?.constraints?.business_unit_ids || []"
                    :userAll="!!user.access_all_business_units"
                    @update="
                        ({ selected, all }) => {
                            this.selectBusinessUnits = selected;
                            this.allBusinessUnits = all;
                        }
                    "
                />
                <Divider />
                <tags-multiselect
                    :userSelected="user?.constraints?.tag_ids || []"
                    :userAll="!!user.access_all_tags"
                    @update="
                        ({ selected, all }) => {
                            this.selectTags = selected;
                            this.allTags = all;
                        }
                    "
                />
                <Divider />
                <div class="cc-form-group">
                    <label for="role-input">{{ $t("role") }}</label>
                    <CcSelect
                        id="role-input"
                        v-model="user.role"
                        :options="roles"
                        required
                    ></CcSelect>
                </div>
                <RoleDefinitions />
            </form>
        </div>

        <template #footer>
            <div class="flex justify-content-end gap-2">
                <Button
                    :label="$t('cancel')"
                    @click="$emit('update:showModal', false)"
                    variant="secondary"
                    outlined
                />
                <Button :label="$t('save')" type="save" form="user-form" />
            </div>
        </template>
    </Dialog>
</template>

<script>
import companyApi from "@/api/company";
import Dialog from "primevue/dialog";
import Divider from "primevue/divider";
import BusinessUnitsMultiselect from "./components/BusinessUnitsMultiselect.vue";
import SitesMultiselect from "./components/SitesMultiselect.vue";
import TagsMultiselect from "./components/TagsMultiselect.vue";
import RoleDefinitions from "./components/RoleDefinitions.vue";
import Button from "primevue/button";

export default {
    components: {
        SitesMultiselect,
        BusinessUnitsMultiselect,
        TagsMultiselect,
        Dialog,
        Button,
        RoleDefinitions,
        Divider,
    },
    props: {
        editUser: {
            type: Object,
            required: true,
        },
        showModal: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            sites: this.$store.getters.getSites(),
            businessUnits: this.$store.getters.getBusinessUnits(),
            tags: this.$store.getters.getTags(),
            user: this.editUser,
            roles: [
                {
                    text: this.$t("admin"),
                    value: "admin",
                },
                {
                    text: this.$t("editor"),
                    value: "editor",
                },
                {
                    text: this.$t("contributor"),
                    value: "contributor",
                },
                {
                    text: this.$t("auditor"),
                    value: "auditor",
                },
                {
                    text: this.$t("read-only"),
                    value: "read-only",
                },
            ],
            selectSites: [],
            selectBusinessUnits: [],
            selectTags: [],
            allSites: false,
            allBusinessUnits: false,
            allTags: false,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.user = { ...this.editUser };
            this.allSites = !!this.editUser.access_all_sites;
            this.allBusinessUnits = !!this.editUser.access_all_business_units;
            this.allTags = !!this.editUser.access_all_tags;
            this.selectTags = this.editUser.constraints?.tag_ids || [];
            this.selectSites = this.editUser.constraints?.site_ids || [];
            this.selectBusinessUnits =
                this.editUser.constraints?.business_unit_ids || [];
        },
        async handleSave() {
            await this.updateUserRestrictions();
            this.$emit("saved");
        },
        async updateUserRestrictions() {
            try {
                // Make sure that no sites are selected if the user has access to all sites
                // Same for business units
                if (this.allSites) this.selectSites = [];
                if (this.allBusinessUnits) this.selectBusinessUnits = [];
                if (this.allTags) this.selectTags = [];

                await companyApi.updateUserRestrictions({
                    ...this.user,
                    access_all_sites: this.allSites,
                    access_all_business_units: this.allBusinessUnits,
                    access_all_tags: this.allTags,
                    constraints: {
                        site_ids: this.selectSites,
                        business_unit_ids: this.selectBusinessUnits,
                        tag_ids: this.selectTags,
                    },
                });
            } catch (error) {
                console.log(error);
            }
        },
    },
    watch: {
        showModal: {
            handler(val) {
                if (val) {
                    this.init();
                }
            },
        },
    },
};
</script>
