<template>
    <div>
        <div
            class="menu-select__item"
            :class="
                selectedBus.includes(bu.id) ? 'menu-select__item--selected' : ''
            "
            v-for="bu in availableBus"
            :key="bu.id"
            @click="handleInput(bu.id)"
        >
            {{ bu.name }}
        </div>
    </div>
</template>

<script>
import * as types from "@/store/types";

import { mapState } from "vuex";

export default {
    computed: {
        ...mapState({
            businessUnits: (state) => state.data.businessUnits,
            selectedBus: (state) => state.filters.businessUnits,
        }),
        availableBus() {
            return [
                ...this.businessUnits,
                { name: this.$t("unassigned"), id: -1 },
            ];
        },
    },
    methods: {
        handleInput(id) {
            // Check if the site has been selected already
            const buIndex = this.selectedBus.findIndex((x) => x === id);

            // If it hasn't, add it to the list of selected sites
            if (buIndex === -1) {
                const selectedBus = [...this.selectedBus, id];
                return this.$store.dispatch(types.SET_FILTER, {
                    businessUnits: selectedBus,
                });
            }

            // If it has, remove it from the list of selected sites
            const selectedBus = [
                ...this.selectedBus.slice(0, buIndex),
                ...this.selectedBus.slice(buIndex + 1),
            ];
            this.$store.dispatch(types.SET_FILTER, {
                businessUnits: selectedBus,
            });
        },
    },
};
</script>
