<template>
    <div class="flex flex-column gap-4">
        <Card>
            <template #title>
                <div class="flex justify-content-between align-items-center">
                    <h2>{{ $t("companyStructure_header") }}</h2>

                    <div class="flex gap-3">
                        <Button
                            @click="handleShowSiteGroupModal(null)"
                            :label="$t('companyStructure_new_group')"
                            v-if="$can('create', 'site_group')"
                            size="small"
                        ></Button>
                        <Button
                            @click="handleShowSiteModal(null)"
                            :label="$t('companyStructure_new_site')"
                            v-if="$can('create', 'site')"
                            size="small"
                        ></Button>
                    </div>
                </div>
            </template>

            <template #content>
                <CompanySites
                    :sites="sites"
                    :siteGroups="siteGroups"
                    @edit-site="handleShowSiteModal"
                    @edit-site-group="handleShowSiteGroupModal"
                />
            </template>
        </Card>

        <Card>
            <template #title>
                <div class="flex justify-content-between align-items-center">
                    <h2>{{ $t("companyBusinessUnits_header") }}</h2>

                    <div class="flex-gap-3">
                        <Button
                            @click="$refs.businessUnits.showFormModal = true"
                            :label="$t('companyBusinessUnits_button_new_unit')"
                            size="small"
                        ></Button>
                    </div>
                </div>
            </template>
            <template #content>
                <company-business-units
                    v-if="$can('read', 'business_unit')"
                    ref="businessUnits"
                ></company-business-units>
            </template>
        </Card>

        <!-- EDIT FORMS -->
        <site-modal
            :siteGroups="siteGroups"
            @update="init"
            :site="site"
            v-model="showSiteModal"
        />
        <site-group-modal
            @update="init"
            :siteGroup="siteGroup"
            v-model="showSiteGroupModal"
        />
    </div>
</template>

<script>
import CompanyBusinessUnits from "@/components/company/structure/CompanyBusinessUnits";
import SiteGroupModal from "@/components/company/structure/SiteGroupModal.vue";
import SiteModal from "@/components/company/structure/SiteModal.vue";
import CompanySites from "./structure/CompanySites.vue";
import Card from "primevue/card";
import Button from "primevue/button";
import { flagMapper } from "@/util/countries.js";
import companyApi from "@/api/company";
import * as types from "@/store/types.js";

export default {
    components: {
        SiteModal,
        SiteGroupModal,
        CompanyBusinessUnits,
        CompanySites,
        Card,
        Button,
    },
    data() {
        return {
            site: {},
            siteGroup: {},
            sites: [],
            siteGroups: [],
            showSiteModal: false,
            showSiteGroupModal: false,
        };
    },
    computed: {
        flagMapper() {
            return flagMapper;
        },
    },
    mounted() {
        this.loadStructure();
    },
    methods: {
        init() {
            this.sites = [];
            this.siteGroups = [];
            this.site = {};
            this.siteGroup = {};
            this.showSiteModal = false;
            this.loadStructure();
        },
        async loadStructure() {
            try {
                const result = await companyApi.getStructure();
                const structure = result.data;
                this.sites = structure.sites;
                this.siteGroups = structure.siteGroups;

                this.updateStoreSites(structure);
            } catch (error) {
                console.log(error);
            }
        },
        updateStoreSites() {
            const GroupSites = [
                ...new Set(this.siteGroups.flatMap((obj) => obj.sites.flat())),
            ];
            const sites = this.sites;
            const allSites = [...GroupSites, ...sites];
            this.$store.dispatch(types.SET_DATA, {
                name: "sites",
                data: allSites,
            });
        },
        handleShowSiteGroupModal(siteGroup) {
            if (!this.$can("update", "site_group")) return;
            this.siteGroup = siteGroup;
            this.showSiteGroupModal = true;
        },
        handleShowSiteModal(site) {
            if (!this.$can("update", "site")) return;
            this.site = site;
            this.showSiteModal = true;
        },
    },
};
</script>
