<template>
    <div v-if="customMetric">
        <form
            id="input-form"
            @submit.prevent="handleFormSubmit"
            class="cc-form"
        >
            <div class="cc-form-group cc-form-group--required">
                <!-- TITLE -->
                <label for="metric_name">
                    {{ $t("label_metric_name") }}
                </label>
                <CcInput
                    id="metric_name"
                    :placeholder="$t('enter_metric_name')"
                    :modelValue="localCustomMetric.name"
                    @update:model-value="handleMetricInput({ name: $event })"
                    required
                ></CcInput>
            </div>

            <!-- UNIT -->
            <div class="cc-form-group cc-form-group--required">
                <label for="metric-unit">
                    {{ $t("label_unit") }}
                </label>
                <CcInput
                    id="metric-unit"
                    :placeholder="$t('metric_unit_placeholder')"
                    :modelValue="localCustomMetric.unit"
                    @update:model-value="handleMetricInput({ unit: $event })"
                    required
                ></CcInput>
                <span class="cc-form__hint">
                    {{ $t("metric_unit_text") }}
                </span>
            </div>

            <Transition name="slide-down" mode="out-in">
                <Message
                    severity="info"
                    icon="fas fa-info-circle"
                    class="my-4"
                    v-if="localCustomMetric.unit"
                >
                    <span class="text-sm">
                        {{ $t("metric_unit_alert") }}
                        {{ localCustomMetric.unit }}.
                    </span>
                </Message>
            </Transition>

            <!-- DESCRIPTION -->
            <div class="cc-form-group">
                <label for="metric_description">
                    {{ $t("label_description") }}
                </label>
                <Textarea
                    id="metric_description"
                    type="text"
                    :placeholder="$t('cm_enter_description')"
                    :modelValue="localCustomMetric.description"
                    @update:model-value="
                        handleMetricInput({ description: $event })
                    "
                    rows="5"
                ></Textarea>
            </div>

            <MetricsDomainSelector
                ref="domainSelector"
                :custom-metric="localCustomMetric"
                :validate="validate"
                @input:metric="handleMetricInput"
            />
        </form>
    </div>
</template>

<script>
import MetricsDomainSelector from "./MetricDomainSelector.vue";

import customMetricsApi from "@/api/custom_metrics";
import Message from "primevue/message";
import Textarea from "primevue/textarea";

import * as types from "@/store/types";

export default {
    name: "MetricDataInput",
    components: {
        MetricsDomainSelector,
        Message,
        Textarea,
    },
    props: {
        customMetric: {
            type: Object,
            required: false,
        },
        modal: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            domainState: null,
            validate: false,
        };
    },
    computed: {
        localCustomMetric: {
            get() {
                return {
                    ...this.customMetric,
                    siteIds: Array.isArray(this.customMetric.siteIds)
                        ? this.customMetric.siteIds
                        : this.customMetric.siteIds
                              ?.split(",")
                              .map((x) => parseInt(x)),
                    businessUnitIds: Array.isArray(
                        this.customMetric.businessUnitIds
                    )
                        ? this.customMetric.businessUnitIds
                        : this.customMetric.businessUnitIds
                              ?.split(",")
                              .map((x) => parseInt(x)),
                };
            },
            set(value) {
                this.$emit("update:metric", value);
            },
        },
        nameState() {
            return this.localCustomMetric?.name?.length >= 1;
        },
        unitState() {
            return this.localCustomMetric?.unit?.length >= 1;
        },
    },
    methods: {
        async handleFormSubmit() {
            this.validate = true;

            // Check for selected bu/site (multiselect component doesn't halt submit)
            if (
                this.localCustomMetric.global_business_unit === false &&
                this.localCustomMetric.businessUnitIds.length === 0
            )
                return;
            if (
                this.localCustomMetric.global_site === false &&
                this.localCustomMetric.siteIds.length === 0
            )
                return;

            // Parse data
            const customMetric = {
                ...this.localCustomMetric,
                site_limitation_ids: this.localCustomMetric.siteIds?.toString(),
                business_unit_limitation_ids:
                    this.localCustomMetric.businessUnitIds?.toString(),
            };
            if (this.localCustomMetric.id) await this.editMetric(customMetric);
            else await this.createNewMetric(customMetric);

            this.$emit("close");
            this.$emit("refresh");

            this.validate = false;
        },
        async createNewMetric(customMetric) {
            try {
                const result = await customMetricsApi.add(customMetric);
                this.$store.dispatch(types.SET_TOAST, result.message);
            } catch (error) {
                console.log(error);
            }
        },
        async editMetric(customMetric) {
            try {
                await customMetricsApi.update(
                    this.customMetric.id,
                    customMetric
                );
                this.$store.dispatch(types.SET_TOAST, this.$t("cm_updated"));
            } catch (error) {
                console.log(error);
            }
        },
        handleMetricInput(input) {
            this.localCustomMetric = {
                ...this.localCustomMetric,
                ...input,
            };
        },
    },
};
</script>
