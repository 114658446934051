<script>
import { mapState } from "vuex";
import * as types from "@/store/types";

export default {
    computed: {
        ...mapState({
            filters: (state) => state.filters,
        }),
    },
    methods: {
        setFilter(filter) {
            this.$store.dispatch(types.SET_FILTER, filter);
        },
    },
};
</script>
