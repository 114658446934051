import { getBaseURL } from "./util/host";
import { useAuth0 } from "@auth0/auth0-vue";
const auth0 = useAuth0();
const axios = require("axios");

// Default config options
const defaultOptions = {
    baseURL: getBaseURL(),
    headers: {
        "Content-Type": "application/json",
    },
};

// Create instance
let axiosInstance = axios.create(defaultOptions);

axiosInstance.interceptors.request.use(
    (request) => {
        request.headers["CompanyId"] =
            sessionStorage.getItem("selectedCompany");
        request.headers["Authorization"] = `Bearer ${sessionStorage.getItem(
            "auth_token"
        )}`;
        return request;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;
