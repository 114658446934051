export default {
    methods: {
        generateValues(key) {
            switch (key) {
                case "site":
                    this.localData.global_site = false;
                    this.localData.global_business_unit = true;
                    this.siteIds = [];
                case "bu":
                    this.localData.global_business_unit = false;
                    this.localData.global_site = true;
                    this.businessUnitIds = [];
                    break;
                case "combined":
                    this.localData.global_site = false;
                    this.localData.global_business_unit = false;
                    break;
                default:
                    this.localData.global_site = true;
                    this.localData.global_business_unit = true;
                    this.siteIds = [];
                    this.businessUnitIds = [];
            }
        },
        selectedDomain(item) {
            switch (true) {
                case item.global_site === 1 && item.global_business_unit === 0:
                    this.domain = this.$t("metric_bu_specific");
                    break;
                case item.global_site === 0 && item.global_business_unit === 1:
                    this.domain = this.$t("metric_site_specific");
                    break;
                case item.global_site === 1 && item.global_business_unit === 1:
                    this.domain = this.$t("metric_whole_company");
                    break;
                case item.global_site === 0 && item.global_business_unit === 0:
                    this.domain = this.$t("metric_combined");
                    break;
                default:
                    null;
                    break;
            }
        },
    },
};
