<template>
    <div v-if="this.$route.query.action !== 'login'">
        <div class="row no-gutters">
            <div class="col-12 col-md-6">
                <div
                    class="flex flex-column justify-content-center align-items-center login__form"
                >
                    <b-img
                        src="letter_logo.svg"
                        height="42"
                        class="login__logo text-center"
                    />
                    <span class="login__fields text-left">
                        <h1>{{ $t("login_header") }}</h1>
                        <p class="login__fields-text">
                            {{ $t("login_if_have_account_text") }}
                        </p>
                        <b-button
                            variant="primary"
                            class="w-full"
                            @click="login"
                        >
                            {{ $t("login_button") }}
                        </b-button>
                        <hr />
                        <p class="login__fields-text">
                            {{ $t("login_trial_text") }}
                        </p>
                        <b-button
                            variant="primary"
                            class="w-full"
                            @click="freeTrial"
                        >
                            {{ $t("login_button_free_trial") }}
                        </b-button>
                    </span>
                </div>
            </div>
            <div class="d-none d-sm-block col-md-6 login__background"></div>
        </div>
    </div>
</template>

<script>
import AuthMixin from "@/mixins/auth/AuthMixin";

export default {
    name: "CCLogin",
    components: {},
    mixins: [
        AuthMixin, // login, logout
    ],
    data() {
        return {};
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            if (this.$route.query.action === "login") this.login();
        },
        freeTrial() {
            this.$router.push("starttrial");
        },
    },
};
</script>
